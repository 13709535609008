<template>
  <div>
    <div class="add-padding wrapper-grey">
      <fieldset>
        <combo-field
          label="Test type:"
          v-model="report.payload.inspection.test_type"
          name="inspection.test_type"
          :values="
            ['Functional', 'Full Hour'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Duration of test:"
          v-model="report.payload.inspection.duration_of_test"
          name="inspection.duration_of_test"
          :values="
            ['Functional', 'Three Hour'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Key Switches Installed:"
          v-model="report.payload.inspection.key_switches_installed"
          name="inspection.key_switches_installed"
          :values="
            ['Functional', 'Three Hour'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Fixed Signage:"
          v-model="report.payload.inspection.fixed_signage"
          name="inspection.fixed_signage"
          :values="
            ['Yes', 'No', 'Partially', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="External To Exit:"
          v-model="report.payload.inspection.external_to_exit"
          name="inspection.external_to_exit"
          :values="
            ['Yes', 'No', 'Partially', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Are Emergency Luminaries Suitably Located:"
          v-model="report.payload.inspection.emergency_luminaries_suitably_located"
          name="inspection.emergency_luminaries_suitably_located"
          :values="
            ['Yes', 'No', 'Partially', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="System Type:"
          v-model="report.payload.inspection.system_type"
          name="inspection.system_type"
          :values="
            ['Maintained', 'Non-Maintained'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
    </div>
  </div>
</template>

<script>
import ReportProps from '@js/mixins/ReportProps'
import ComboField from '@components/ComboField'

export default {
  name: 'Inspection',
  components: {
    ComboField
  },
  mixins: [ReportProps]
}
</script>
