export default {
  methods: {
    resetPassword () {
      return this.$server.updateResource(this.resource, {
        handler: 'reset-password',
        id: this.form.id
      })
    }
  }
}
