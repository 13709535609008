<template>
  <div class="buttons is-right are-rounded">
    <template v-if="!offline">
      <b-button type="open" tag="router-link" :to="route('reports.online.edit', {id: report.id})" icon-right="eye">
      </b-button>
      <template v-if="$auth.isAdministrator">
        <b-button tag="a" :href="report.preview_export_url"
                  target="_blank" icon-right="search-plus">
        </b-button>
        <b-button tag="a" :href="report.export_url"
                  target="_blank" icon-right="file-pdf">
        </b-button>
      </template>
      <b-button v-if="$auth.isAdministrator"
                @click="$emit('destroy', report.id)"
                type="is-danger"
                icon-right="trash"/>
      <template v-if="$auth.isAdministrator">
        <b-button v-if="!report.archived_at"
                  @click="$emit('archive', report.id)"
                  type="is-light"
                  icon-right="file-archive">
        </b-button>
        <b-button v-else
                  @click="$emit('unarchive', report.id)"
                  type="is-light"
                  icon-right="file-archive">
        </b-button>
      </template>
    </template>
    <template v-else>
      <b-button tag="router-link"
                :to="route('reports.offline.edit', null, {id: report.id})"
                icon-right="eye">
      </b-button>
      <b-button @click="$emit('sync', report.id)"
                icon-right="sync">
      </b-button>
      <b-button @click="$emit('destroy', report.id)"
                type="is-danger"
                icon-right="trash"/>
    </template>
  </div>
</template>

<script>
import REPORT_STATUS from '@js/report-status'

export default {
  name: 'ReportAction',
  props: {
    report: {
      required: true,
      type: Object
    },
    offline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isCompleted () {
      return this.report.status === REPORT_STATUS.COMPLETED
    }
  }
}
</script>
