<template>
  <div class="add-padding">
    <div class="upload-list">
      <ul class="columns is-mobile is-multiline">
        <li
          class="column is-4"
          v-for="(image, index) in assets"
          :key="image.id"
        >
          <button
            class="delete is-small"
            type="button"
            @click="deleteAsset(index)"
            :data-test="`remove-asset-${index}`"
          ></button>
          <preview mode="thumbnail" :file="`${backendUrl}/dashboard/assets/${image.path}`"></preview>
        </li>
        <li class="column is-4"
            v-for="(image, index) in images"
            :key="index">
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile(index)"
            :data-test="`remove-evidence-${index}`"
          ></button>
          <preview mode="thumbnail" :file="image" :key="image.lastModified"></preview>
        </li>
      </ul>
      <b-field class="file">
        <b-upload v-model="images"
                  multiple
                  expanded
                  @input="fileAdded">
          <a class="button is-primary">
            <span>Add photo</span>
          </a>
        </b-upload>
      </b-field>
      <hr/>
    </div>
  </div>
</template>

<script>
import ReportProps from '@js/mixins/ReportProps'
import Preview from '@components/Preview'

export default {
  name: 'Evidence',
  components: {
    Preview
  },
  mixins: [ReportProps],
  data () {
    return {
      assets: [],
      images: [],
      file: {}
    }
  },
  mounted () {
    this.assets = JSON.parse(
      JSON.stringify(
        this.originalReport.assets.filter((asset) => asset.type === 'evidence')
      )
    )

    const rawEvidence = this.originalReport.assets.filter(asset => asset.type === 'raw-evidence')

    if (rawEvidence.length) {
      this.images = rawEvidence.map(object => object.file)

      this.fileAdded()
    }
  },
  methods: {
    deleteDropFile (index) {
      this.images.splice(index, 1)
    },
    deleteAsset (index) {
      const target = this.assets[index]

      this.report.deletedAssets.push(target.id)

      this.assets.splice(index, 1)

      this.originalReport.assets.splice(
        this.originalReport.assets.findIndex((item) => item.id === target.id),
        1
      )
    },
    fileAdded () {
      this.report.images = this.images

      this.$emit('input', this.report)
    }
  }
}
</script>
