import Vue from 'vue'

const MIDDLEWARE = {
  GUEST: (to, from, next) => {
    const vue = new Vue()

    if (vue.$auth.user) {
      next(to.query.redirect || vue.$auth.redirectPath)

      return true
    }
  },
  AUTH: (to, from, next) => {
    const vue = new Vue()

    if (!vue.$auth.user) {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })

      return true
    }
  },
  ADMINISTRATOR: (to, from, next) => {
    const vue = new Vue()

    if (!vue.$auth.isAdministrator) {
      next({
        name: 'unauthorized'
      })

      return true
    }
  },
  ENGINEER: (to, from, next) => {
    const vue = new Vue()

    if (!vue.$auth.isEngineer) {
      next({
        name: 'unauthorized'
      })

      return true
    }
  }
}

export default MIDDLEWARE
