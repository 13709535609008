const qs = require('qs')

export const Filter = (attributes) => {
  return Object.assign({
    defaultSortOrder: null,
    sortField: null,
    sortOrder: null,
    page: 1
  }, attributes)
}

export default function (config) {
  config = Object.assign({
    loadFromOffline: false
  }, config)

  return {
    data () {
      return {
        query: {
          data: [],
          total: 0,
          offset: 0,
          count: 0,
          loading: false,
          perPage: 10
        },

        filter: this.filterConfig()
      }
    },
    computed: {
      tableConfig () {
        return {
          data: this.query.data,
          loading: this.query.loading,
          paginated: true,
          backendPagination: true,
          total: this.query.total,
          perPage: this.query.perPage,
          ariaNextLabel: 'Next page',
          ariaPreviousLabel: 'Previous page',
          ariaPageLabel: 'Page',
          ariaCurrentLabel: 'Current page',
          backendSorting: true,
          mobileCards: false,
          defaultSortDirection: this.filter.defaultSortOrder,
          defaultSort: [this.filter.sortField, this.filter.sortOrder]
        }
      },
      noRecords () {
        return !this.query.loading && !this.query.data.length
      },
      shouldLoadFromOffline () {
        return this.$network.isOffline
      }
    },
    mounted () {
      Object.assign(this.filter, this.$route.query)

      this.load()
    },
    methods: {
      offlineFetchData () {
        return Promise.reject(Error('No offlineFetchData method defined.'))
      },
      load () {
        this.query.loading = true

        this.query.data = []

        if (config.loadFromOffline || this.shouldLoadFromOffline) {
          return this.offlineFetchData()
            .then(({
              data,
              total
            }) => {
              Object.assign(this.query, {
                data,
                total,
                offset: ((this.filter.page - 1) * this.query.perPage) + 1,
                count: this.filter.page === (Math.ceil(total / this.query.perPage))
                  ? total
                  : this.filter.page * this.query.perPage,
                loading: false,
                perPage: 10
              })
            })
            .then(() => {
              return this.testUrl()
            })
            .catch((error) => {
              this.query.loading = false

              this.query.total = 0

              throw error
            })
        }

        return Promise.resolve()
          .then(() => {
            return this.testUrl()
          })
          .then(() => {
            return this.fetchData()
              .then(({ data }) => {
                this.query.loading = false

                Object.keys(data.vue).forEach(key => {
                  this[key] = Object.assign(this[key], data.vue[key])
                })

                if (this.onLoadComplete) {
                  this.onLoadComplete()
                }
              })
              .catch((error) => {
                this.query.loading = false

                this.query.total = 0

                throw error
              })
          })
      },
      testUrl () {
        const currentPath = qs.stringify(qs.parse(window.location.href.replace(/^.+\?/, '')))

        const newPath = qs.stringify(this.filter)

        if (currentPath !== newPath) {
          return this.updateUrl()
        }
      },
      fetchData () {
        return Promise.reject(Error('fetchData method not not defined'))
      },
      updateUrl () {
        return Promise.reject(Error('updateUrl method not not defined'))
      },
      filterConfig () {
        return {}
      },
      goToPage (page) {
        this.filter.page = page

        return this.load()
      },
      updateFilter () {
        this.filter.page = 1

        this.$boolean.set('filter', false)

        return this.load()
      },
      resetFilter () {
        Object.assign(this.filter, this.filterConfig())

        this.$boolean.set('filter', false)

        return this.load()
      },
      onSort (field, order) {
        this.filter.sortField = field

        this.filter.sortOrder = order

        this.load()
      },
      deleteRow (id) {
        if (!confirm('Are you sure you want to delete?')) {
          return
        }

        return this.$server.destroyResource(this.resource, id)
          .then(() => {
            return this.load()
          })
      }
    }
  }
}
