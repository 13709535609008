export default {
  props: {
    report: {
      required: true,
      type: Object
    },
    originalReport: {
      required: true,
      type: Object
    }
  }
}
