var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"add-padding wrapper-grey"},[_c('fieldset',[_c('combo-field',{attrs:{"label":"Test type:","name":"inspection.test_type","values":['Functional', 'Full Hour'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.test_type),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "test_type", $$v)},expression:"report.payload.inspection.test_type"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Duration of test:","name":"inspection.duration_of_test","values":['Functional', 'Three Hour'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.duration_of_test),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "duration_of_test", $$v)},expression:"report.payload.inspection.duration_of_test"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Key Switches Installed:","name":"inspection.key_switches_installed","values":['Functional', 'Three Hour'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.key_switches_installed),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "key_switches_installed", $$v)},expression:"report.payload.inspection.key_switches_installed"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Fixed Signage:","name":"inspection.fixed_signage","values":['Yes', 'No', 'Partially', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.fixed_signage),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "fixed_signage", $$v)},expression:"report.payload.inspection.fixed_signage"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"External To Exit:","name":"inspection.external_to_exit","values":['Yes', 'No', 'Partially', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.external_to_exit),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "external_to_exit", $$v)},expression:"report.payload.inspection.external_to_exit"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Emergency Luminaries Suitably Located:","name":"inspection.emergency_luminaries_suitably_located","values":['Yes', 'No', 'Partially', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.emergency_luminaries_suitably_located),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "emergency_luminaries_suitably_located", $$v)},expression:"report.payload.inspection.emergency_luminaries_suitably_located"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"System Type:","name":"inspection.system_type","values":['Maintained', 'Non-Maintained'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.system_type),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "system_type", $$v)},expression:"report.payload.inspection.system_type"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }