<template>
  <div>
    <div class="add-padding wrapper-grey">
      <fieldset>
        <combo-field
          label="Visit Type:"
          v-model="report.payload.inspection.visit_type"
          name="inspection.visit_type"
          :values="
            ['New Contract', 'Visual Inspection', 'Functional Inspection', 'One Off Inspection'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="System Make:"
          v-model="report.payload.inspection.system_make"
          name="inspection.system_make"
          :values="
            ['SE Control', 'Velux', 'Functional Inspection', 'One Off Inspection'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <b-field label="System Model:" v-bind="showError('inspection.system_model')">
          <b-input
            v-model="report.payload.inspection.system_model"></b-input>
        </b-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Is Interlink Functional:"
          v-model="report.payload.inspection.type_of_vent"
          name="inspection.type_of_vent"
          :values="
            ['Smoke Vent Shaft', 'Window Vent', 'Smoke Roof Vent', 'Skylight Vent', 'Mechanical Extract'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <b-field label="No. of Smoke Detectors:" v-bind="showError('inspection.smoke_detectors')">
          <b-input
            v-model="report.payload.inspection.smoke_detectors"></b-input>
        </b-field>
      </fieldset>
      <fieldset>
        <b-field label="No. of Manual Activation Points:" v-bind="showError('inspection.manual_activation_points')">
          <b-input
            v-model="report.payload.inspection.manual_activation_points"></b-input>
        </b-field>
      </fieldset>
      <fieldset>
        <b-field label="No. of Panels:" v-bind="showError('inspection.panels')">
          <b-input
            v-model="report.payload.inspection.panels"></b-input>
        </b-field>
      </fieldset>
      <fieldset>
        <b-field label="No. of Linked Panels:" v-bind="showError('inspection.linked_panels')">
          <b-input
            v-model="report.payload.inspection.linked_panels"></b-input>
        </b-field>
      </fieldset>
    </div>
  </div>
</template>

<script>
import ReportProps from '@js/mixins/ReportProps'
import ComboField from '@components/ComboField'

export default {
  name: 'Inspection',
  components: {
    ComboField
  },
  mixins: [ReportProps]
}
</script>
