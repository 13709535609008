var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"add-padding wrapper-grey"},[_c('fieldset',[_c('combo-field',{attrs:{"label":"Manual Test Button Tested:","name":"inspection.manual_test_button_tested","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.manual_test_button_tested),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "manual_test_button_tested", $$v)},expression:"report.payload.inspection.manual_test_button_tested"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Smoke Test Carried Out:","name":"inspection.smoke_test_carried_out","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.smoke_test_carried_out),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "smoke_test_carried_out", $$v)},expression:"report.payload.inspection.smoke_test_carried_out"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Battery Replaced:","name":"inspection.battery_replaced","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.battery_replaced),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "battery_replaced", $$v)},expression:"report.payload.inspection.battery_replaced"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is Interlink Functional:","name":"inspection.is_interlink_functional","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.is_interlink_functional),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "is_interlink_functional", $$v)},expression:"report.payload.inspection.is_interlink_functional"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is System Satisfactory:","name":"inspection.is_system_satisfactory","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.is_system_satisfactory),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "is_system_satisfactory", $$v)},expression:"report.payload.inspection.is_system_satisfactory"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }