<template>
  <div id="app">
    <div>
      <component :is="layout">
        <router-view/>
      </component>
    </div>
  </div>
</template>

<script>
import Default from './layouts/Default'
import Engineer from './layouts/Engineer'
import Administrator from './layouts/Administrator'

// eslint-disable-next-line no-unused-vars
async function logQuota () {
  if (navigator.storage && navigator.storage.estimate) {
    const quota = await navigator.storage.estimate()
    // quota.usage -> Number of bytes used.
    // quota.quota -> Maximum number of bytes available.
    const percentageUsed = (quota.usage / quota.quota) * 100
    console.log(`You've used ${percentageUsed}% of the available storage.`)
    const remaining = (quota.quota - quota.usage) / 1e+9
    console.log(`You can write up to ${remaining.toFixed(2)} more GB.`)

    return {
      quota: (quota.quota / 1e+9),
      usage: (quota.usage / 1e+9)
    }
  }
}

export default {
  components: {
    Default,
    Engineer,
    Administrator
  },
  computed: {
    layout () {
      if (this.$auth.isAdministrator) {
        return 'administrator'
      }

      if (this.$auth.isEngineer) {
        return 'engineer'
      }

      return 'default'
    }
  },
  mounted () {
    logQuota()
  }
}
</script>
