import Dexie from 'dexie'

const db = new Dexie('quantum')

db.version(5).stores({
  sites: 'id,name,street1,street2,town,postcode,customers,blocks,contact_email,contact_name,contact_phone,simpro_id',
  materials: 'id,name',
  reports: '++id,site,block,template,payload,assets,signature,action,handler,next_inspection_at'
})

export const Report = function () {
  return {
    site: null,
    block: null,
    template: null,
    payload: null,
    assets: [],
    signature: null,
    action: null,
    handler: null,
    created_at: null
  }
}

export default db
