<template>
    <section>
        <div class="content">
            <header class="list-header">
                <ul>
                    <li>
                        <router-link :to="route('administrators')">
                            <i class="fas fa-chevron-left"></i>
                            <span>Back</span>
                        </router-link>
                    </li>
                    <li><strong>Edit Administrator</strong></li>
                </ul>
            </header>
            <p v-if="!ready">Loading...</p>
            <form v-else
                  method="post"
                  @submit.prevent="submit" class="smaller-form">
                <div class="columns is-multiline">
                    <div class="column is-4">
                        <b-field label="First name"
                                 v-bind="showError('first_name')">
                            <b-input type="text"
                                     v-model="form.first_name">
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column is-4">
                        <b-field label="Last name"
                                 v-bind="showError('last_name')">
                            <b-input type="text"
                                     v-model="form.last_name">
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column is-4">
                        <b-field label="Email"
                                 v-bind="showError('email')">
                            <b-input type="email"
                                     v-model="form.email">
                            </b-input>
                        </b-field>
                    </div>
                </div>
                <div class="buttons is-right">
                    <b-button :disabled="isLoading">
                        <router-link :to="route('administrators')">
                            Back to list
                        </router-link>
                    </b-button>
                    <b-button type="is-purple"
                              native-type="submit"
                              :loading="isLoading">
                        Save
                    </b-button>
                    <b-button type="is-success" @click="resetPassword">
                        Reset password
                    </b-button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import LaravelForm, { FormAttributes } from '@js/mixins/LaravelForm'
import LaravelResourceForm from '@js/mixins/LaravelResourceForm'
import AuthenticableEdit from '@js/mixins/AuthenticableEdit'

export default {
  name: 'AdministratorEdit',
  mixins: [LaravelForm, LaravelResourceForm({ load: true, edit: true }), AuthenticableEdit],
  data () {
    return {
      resource: 'administrator'
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    formConfig () {
      return new FormAttributes({
        first_name: '',
        last_name: '',
        email: ''
      })
    }
  }
}
</script>
