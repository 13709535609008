var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"add-padding wrapper-grey"},[_c('fieldset',[_c('combo-field',{attrs:{"label":"Alarm Sounders Tested:","name":"inspection.alarm_sounders_tested","values":['Yes', 'No', 'Partially', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.alarm_sounders_tested),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "alarm_sounders_tested", $$v)},expression:"report.payload.inspection.alarm_sounders_tested"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Manual Call Points Tested:","name":"inspection.manual_call_points_tested","values":['Yes', 'No', 'Partially', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.manual_call_points_tested),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "manual_call_points_tested", $$v)},expression:"report.payload.inspection.manual_call_points_tested"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Manual Call Points Tested:","name":"inspection.manual_call_points_tested","values":['Yes', 'No', 'Partially', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.manual_call_points_tested),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "manual_call_points_tested", $$v)},expression:"report.payload.inspection.manual_call_points_tested"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Smoke Detectors Tested:","name":"inspection.smoke_detectors_tested","values":['Yes', 'No', 'Partially', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.smoke_detectors_tested),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "smoke_detectors_tested", $$v)},expression:"report.payload.inspection.smoke_detectors_tested"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Batteries Tested:","name":"inspection.batteries_tested","values":['Yes', 'No', 'Partially', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.batteries_tested),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "batteries_tested", $$v)},expression:"report.payload.inspection.batteries_tested"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is System Satisfactory:","name":"inspection.is_system_satisfactory","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.is_system_satisfactory),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "is_system_satisfactory", $$v)},expression:"report.payload.inspection.is_system_satisfactory"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Panel Lamp Test:","name":"inspection.panel_lamp_test","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.panel_lamp_test),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "panel_lamp_test", $$v)},expression:"report.payload.inspection.panel_lamp_test"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Battery Fault Indication:","name":"inspection.battery_fault_indication","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.battery_fault_indication),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "battery_fault_indication", $$v)},expression:"report.payload.inspection.battery_fault_indication"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Zone Fault Indication:","name":"inspection.zone_fault_indication","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.zone_fault_indication),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "zone_fault_indication", $$v)},expression:"report.payload.inspection.zone_fault_indication"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Sounder Fault Indication:","name":"inspection.sounder_fault_indication","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.sounder_fault_indication),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "sounder_fault_indication", $$v)},expression:"report.payload.inspection.sounder_fault_indication"}})],1)]),_c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("Covered During Inspection:")]),_c('fieldset',[_c('combo-field',{attrs:{"label":"Log Book Available for Inspection:","name":"inspection.log_book_available","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.log_book_available),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "log_book_available", $$v)},expression:"report.payload.inspection.log_book_available"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Has Work and Faults be Recorded:","name":"inspection.has_work_and_faults_be_recorded","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.has_work_and_faults_be_recorded),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "has_work_and_faults_be_recorded", $$v)},expression:"report.payload.inspection.has_work_and_faults_be_recorded"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Printer Legible:","name":"inspection.printer_legible","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.printer_legible),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "printer_legible", $$v)},expression:"report.payload.inspection.printer_legible"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Equipment Listed Correctly:","name":"inspection.equipment_listed_correctly","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.equipment_listed_correctly),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "equipment_listed_correctly", $$v)},expression:"report.payload.inspection.equipment_listed_correctly"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is there a Zone map:","name":"inspection.zone_map_available","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.zone_map_available),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "zone_map_available", $$v)},expression:"report.payload.inspection.zone_map_available"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are the Zone mapped:","name":"inspection.zone_mapped","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.zone_mapped),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "zone_mapped", $$v)},expression:"report.payload.inspection.zone_mapped"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Any alterations to system:","name":"inspection.system_alterations","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.system_alterations),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "system_alterations", $$v)},expression:"report.payload.inspection.system_alterations"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Any alterations to building:","name":"inspection.building_alterations","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.report.payload.inspection.building_alterations),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "building_alterations", $$v)},expression:"report.payload.inspection.building_alterations"}})],1),_c('fieldset',[_c('b-field',_vm._b({attrs:{"label":"False alarms occurred during the past 12 months:"}},'b-field',_vm.showError('inspection.false_alarms_within_last_year'),false),[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.report.payload.inspection.false_alarms_within_last_year),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "false_alarms_within_last_year", $$v)},expression:"report.payload.inspection.false_alarms_within_last_year"}})],1)],1),_c('fieldset',[_c('b-field',_vm._b({attrs:{"label":"The above number of false alarms equates to per 100 automatic fire defectors per annum:"}},'b-field',_vm.showError('inspection.false_alarms_per_automatic_fire_detectors_per_year'),false),[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.report.payload.inspection.false_alarms_per_automatic_fire_detectors_per_year),callback:function ($$v) {_vm.$set(_vm.report.payload.inspection, "false_alarms_per_automatic_fire_detectors_per_year", $$v)},expression:"report.payload.inspection.false_alarms_per_automatic_fire_detectors_per_year"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }