<template>
  <section>
    <div class="content">
      <header class="list-header">
        <div class="columns is-vcentered">
          <div class="column is-6">
            <p>Displaying <span v-if="query.data.length">{{ query.offset }}-{{ query.count }} of {{ query.total }} Administrators</span>
            </p>
          </div>
          <div class="column is-6">
            <div class="buttons is-right">
              <b-button type="is-success is-outlined"
                        @click="$boolean.set('filter', true)">
                Filter
              </b-button>
              <router-link :to="route('administrators.create')">
                <b-button type="is-purple is-outlined">
                  Add new Administrator
                </b-button>
              </router-link>
            </div>
          </div>
        </div>
      </header>
      <b-modal :active="$boolean.get('filter')">
        <div class="filter-form">
          <div class="content">
            <header class="list-header">
              <h4>Filter administrators</h4>
            </header>
            <form @submit.prevent="updateFilter" class="smaller-form">
              <div class="columns is-multiline">
                <div class="column is-4">
                  <b-field label="First name">
                    <b-input type="text"
                             v-model="filter.first_name">
                    </b-input>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Email">
                    <b-input type="text"
                             v-model="filter.last_name">
                    </b-input>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Email">
                    <b-input type="text"
                             v-model="filter.email">
                    </b-input>
                  </b-field>
                </div>
              </div>
              <div class="buttons is-right">
                <b-button type="is-purple"
                          native-type="submit">Apply
                </b-button>
                <b-button type="is-success"
                          @click="resetFilter">Reset
                </b-button>
                <b-button @click="$boolean.set('filter', false)">
                  Cancel
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <p v-if="noRecords">No records found</p>
      <b-table v-else
               v-bind="tableConfig"
               @page-change="goToPage"
               @sort="onSort">

        <b-table-column label="ID"
                        sortable v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column label="Name"
                        sortable v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column label="Email"
                        sortable v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column v-slot="props">
          <div class="buttons is-right are-rounded">
            <b-button type="open">
              <router-link :to="route('administrators.edit', {id: props.row.id})">
                <b-icon icon="eye"></b-icon>
              </router-link>
            </b-button>
            <b-button @click="deleteRow(props.row.id)"
                      type="is-danger"
                      icon-right="fas fa-trash"/>
          </div>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>
<script>
import LaravelTable, { Filter } from '@js/mixins/LaravelTable'
import ResourceLaravelTable from '@js/mixins/ResourceLaravelTable'

export default {
  name: 'Users',
  mixins: [LaravelTable(), ResourceLaravelTable],
  data () {
    return {
      resource: 'administrator'
    }
  },
  methods: {
    updateUrl () {
      this.$router.push({
        name: 'administrators',
        query: this.filter
      })
    },
    filterConfig () {
      return new Filter({
        name: '',
        email: ''
      })
    }
  }
}
</script>
