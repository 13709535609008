<template>
  <div>
    <fieldset>
        <autocomplete-field
        placeholder="Start typing a material."
        v-bind="formatter().materials"
        @select="siteSelect" clear-on-select>
        <template slot-scope="props">
          <div class="media">
            <div class="media-content">
              <strong>{{ props.option.name }}</strong> {{ props.option.part_number }}
            </div>
          </div>
        </template>
      </autocomplete-field>
    </fieldset>
    <b-table :data="report.payload.quote.materials">
      <b-table-column label="ID" v-slot="props">
        {{ props.row.id}}
      </b-table-column>
      <b-table-column label="Name" v-slot="props">
        {{ props.row.name}}
      </b-table-column>
      <b-table-column label="Part No." v-slot="props">
        {{ props.row.part_number}}
      </b-table-column>
      <b-table-column label="Quantity" v-slot="props">
        {{ props.row.quantity}}
      </b-table-column>
      <b-table-column v-slot="props">
        <div class="buttons is-rounded is-right">
          <b-button @click="increase(props.row)">+</b-button>
          <b-button @click="decrease(props.row, props.index)">-</b-button>
        </div>
      </b-table-column>
      <template #empty>
        No materials added.
      </template>
    </b-table>
  </div>
</template>
<script>
import ReportProps from '@/mixins/ReportProps'
import db from '@/database'
import AutocompleteField from '@components/AutocompleteField'

export default {
  mixins: [ReportProps],
  components: {
    AutocompleteField
  },
  methods: {
    formatter () {
      return {
        materials: {
          onTyping (name) {
            if (!name.length) {
              this.clearList()
              return
            }

            this.fetching()

            if (this.$auth.isAdministrator) {
              return this.$server.searchResource('material', {
                name
              })
                .then(response => {
                  this.setData(response.data.suggestions)
                })
                .catch((error) => {
                  this.clearList()
                  throw error
                })
                .finally(() => {
                  this.fetchDone()
                })
            }

            return db.materials.filter((material) => {
              return new RegExp(`${name}`, 'i').test(material.name)
            })
              .limit(100)
              .toArray()
              .then(materials => {
                this.setData(materials)
              })
              .catch((error) => {
                this.clearList()
                throw error
              })
              .finally(() => {
                this.fetchDone()
              })
          },
          customFormatter (material) {
            return material.name
          }
        }
      }
    },
    siteSelect (material) {
      if (material) {
        const match = this.report.payload.quote.materials.find(item => item.id === material.id)
        if (match) {
          match.quantity++

          return
        }

        const item = Object.assign({
          quantity: 1
        }, material)

        delete item.created_at

        delete item.updated_at

        this.report.payload.quote.materials.push(item)
      }
    },
    increase (material) {
      material.quantity++
    },
    decrease (material, index) {
      if (material.quantity === 1) {
        this.report.payload.quote.materials.splice(index, 1)

        return
      }

      material.quantity--
    }
  }
}
</script>
