<template>
  <div class="add-padding">
    <h4>Add Materials:</h4>
    <materials :original-report="originalReport" :report="report"></materials>
    <hr>
    <h4>Confirm Labour:</h4>
    <div class="columns is-mobile is-multiline">
      <div class="column is-4">
        <fieldset>
          <b-field label="Hours:" v-bind="showError('quote.labour')">
            <b-input
              type="text"
              data-test="field-quote-labour"
              v-model.number="report.payload.quote.labour"
            ></b-input>
          </b-field>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
import ReportProps from '@/mixins/ReportProps'
import Materials from '@components/Materials'

export default {
  name: 'Quote',
  mixins: [ReportProps],
  components: {
    Materials
  }
}
</script>
