import Vue from 'vue'
import db from './database'

const Lockr = require('lockr')

const syncModel = (name, url) => {
  const vue = new Vue()

  let items = []

  let page = 1

  return new Promise((resolve, reject) => {
    const request = () => {
      return vue.$server.server.get(url || `${name}`, {
        headers: {
          Authorization: `Bearer ${vue.$auth.user.token}`
        },
        params: {
          page: page
        }
      })
        .then(response => {
          items = items.concat(response.data[name].data)

          vue.$assets.current = page
          vue.$assets.total = response.data[name].last_page

          if (response.data[name].last_page !== page) {
            page++

            setTimeout(() => {
              return request()
                .catch(reject)
            }, 1000)

            return
          }

          return db[name].clear()
            .then(() => {
              return db[name].bulkAdd(items)
                .then(() => {
                  vue.$assets.current = 0
                  vue.$assets.total = 1
                })
                .then(resolve)
            })
            .catch(reject)
        })
        .catch(reject)
    }

    request()
  })
}

const fetchSites = () => {
  new Vue().$assets.task = 'Loading sites...'
  return syncModel('sites')
}

const fetchMaterials = () => {
  new Vue().$assets.task = 'Loading materials...'
  return syncModel('materials')
}

const fetchTemplates = () => {
  const vue = new Vue()

  vue.$assets.task = 'Loading templates...'

  return vue.$server.server.get('templates', {
    headers: {
      Authorization: `Bearer ${vue.$auth.user.token}`
    }
  })
    .then(response => {
      vue.$assets.current = 1
      vue.$assets.total = 1

      Lockr.set('templates', response.data.templates)
    })
}

export default function loadAssets (force = false) {
  const vue = new Vue()

  if (vue.$network.warnOffline()) {
    return
  }

  const load = force || Lockr.get('ready') !== 1

  if (!load) {
    return Promise.resolve()
      .then(() => {
        const vue = new Vue()

        vue.$assets.task = 'Finalizing...'

        Lockr.set('ready', 1)

        vue.$auth.ready = true
      })
  }

  Lockr.set('ready', null)

  vue.$auth.ready = false

  vue.$assets.current = 0
  vue.$assets.total = 1

  return Promise.resolve()
    .then(fetchSites)
    .then(fetchMaterials)
    .then(fetchTemplates)
    .then(() => {
      const vue = new Vue()

      vue.$assets.task = 'Finalizing...'

      Lockr.set('ready', 1)

      vue.$auth.ready = true
    })
}
