import Vue from 'vue'
import VueRouter from 'vue-router'
import { Route } from '@js/routing/route'
import '@js/routing/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  routes: Route.all(),
  base: process.env.BASE_URL
})

const lockr = require('lockr')

router.beforeEach(async (to, from, next) => {
  const user = lockr.get('user')

  const vue = new Vue()

  if (user) {
    vue.$auth.user = user

    if (vue.$auth.isAdministrator) {
      vue.$auth.ready = true
    }
  }

  let output = null

  if (!to.meta || !to.meta.middleware) {
    next()

    return
  }

  for (let i = 0; i < to.meta.middleware.length; i++) {
    output = to.meta.middleware[i](to, from, next)

    if (output) {
      break
    }
  }

  if (!output) {
    next()
  }
})

router.afterEach((to, from) => {
  document.title = `Quantum - ${to.meta.title}`
})

export default router
