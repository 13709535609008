<template>
  <fieldset>
    <div class="columns is-multiline is-variable is-1 is-mobile is-align-items-center">
      <div class="column is-10">
        <radio-options
          :label="label"
          v-model="query"
          v-if="isRadio"
          :name="name"
          @input="updateQuery"
          :options="values.map((object) => object.value)"
        >
        </radio-options>
        <b-select
          expanded
          v-if="isDropdown"
          v-model="query"
          @input="updateQuery"
          data-test="dropdown"
        >
          <option value="">Select a value</option>
          <option :value="item.value" v-for="item in values" :key="item.value">
            {{ item.label }}
          </option>
        </b-select>
        <div class="control" v-if="isFreeField">
          <div class="columns is-multilines is-variable is-1">
            <div class="column is-4" v-if="label">
              <label class="label"
              ><strong>{{ label }}</strong></label
              >
            </div>
            <div class="column is-8">
              <input
                class="input"
                v-model="query"
                type="text"
                data-test="free-field"
                @input="updateQuery"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="buttons is-right">
          <button
            type="button"
            class="button is-success"
            @click="toggleField"
            data-test="toggle"
          >
              <span v-if="isDropdown || isRadio">
                <b-icon icon="edit"></b-icon>
              </span>
            <span v-if="isFreeField">Cancel</span>
          </button>
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script>
import RadioOptions from './RadioOptions'

export default {
  components: {
    RadioOptions
  },
  props: {
    label: {
      type: String
    },
    name: {
      type: String
    },
    values: {
      required: true,
      type: Array
    },
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null
    },
    mode: {
      type: String,
      default: 'dropdown'
    }
  },
  data () {
    return {
      query: '',
      showFreeField: false
    }
  },
  created () {
    this.isValueValid()

    this.query = this.value
  },
  watch: {
    value (value, old) {
      this.query = value
    }
  },
  computed: {
    isDropdown () {
      return !this.showFreeField && this.mode === 'dropdown'
    },
    isRadio () {
      return !this.showFreeField && this.mode === 'radio'
    },
    isFreeField () {
      return this.showFreeField
    }
  },
  methods: {
    isValueValid () {
      if (!this.value) {
        return
      }

      if (this.values.find((object) => object.value === this.value)) {
        return
      }

      this.showFreeField = true
    },
    toggleField () {
      this.updateQuery()

      this.showFreeField = !this.showFreeField
    },
    updateQuery () {
      this.$emit('input', this.query)
    }
  }
}
</script>
