import { Route } from '@js/routing/route'
import Sites from '@js/views/Sites'
import SiteCreate from '@js/views/SiteCreate'
import SiteEdit from '@js/views/SiteEdit'
import SiteDetails from '@js/views/SiteDetails'
import SiteBlocks from '@js/views/SiteBlocks'
import Reports from '@js/views/Reports'
import ReportEdit from '@js/views/ReportEdit'
import Users from '@js/views/Users'
import Archive from '@js/views/Archive'
import NotFound from '@js/views/NotFound'
import SiteReports from '@js/views/SiteReports'
import UserEdit from '@js/views/UserEdit'
import Administrators from '@js/views/Administrators'
import UserCreate from '@js/views/UserCreate'
import AdministratorEdit from '@js/views/AdministratorEdit'
import AdministratorCreate from '@js/views/AdministratorCreate'
import ReportCreate from '@js/views/ReportCreate'
import NotAuthorized from '@js/views/NotAuthorized'
import MIDDLEWARE from '@js/routing/middleware'
import Login from '@js/views/Login'
import Vue from 'vue'
import ReportsOffline from '@/views/ReportsOffline'
import ReportsOnline from '@/views/ReportsOnline'
import ReportView from '@/views/ReportView'

Route.add('/', Login)
  .name('login')
  .middleware(MIDDLEWARE.GUEST)
  .title('Login')

Route.add('/sites', Sites)
  .name('sites')
  .middleware(MIDDLEWARE.AUTH)
  .title('Sites')

Route.add('/sites/create', SiteCreate)
  .name('sites.create')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Create a site')

Route.add('/sites/:id', SiteEdit)
  .name('sites.edit')
  .middleware(MIDDLEWARE.AUTH)
  .redirect('sites.details')
  .title('Edit site')
  .children([
    Route.create('details', SiteDetails)
      .name('sites.details')
      .middleware(MIDDLEWARE.AUTH)
      .title('Site details'),
    Route.create('blocks', SiteBlocks)
      .name('sites.blocks')
      .middleware(MIDDLEWARE.AUTH)
      .title('Site blocks'),
    Route.create('reports', SiteReports)
      .name('sites.reports')
      .middleware(MIDDLEWARE.AUTH)
      .title('Site reports')
  ])

Route.add('/reports', Reports)
  .redirect(function (to) {
    const vue = new Vue()

    if (!vue.$auth.user) {
      return {
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      }
    }

    return {
      name: vue.$auth.isAdministrator ? 'reports.online' : 'reports.offline',
      query: to.query
    }
  })
  .children([
    Route.create('online', ReportsOnline)
      .name('reports.online')
      .middleware(MIDDLEWARE.AUTH)
      .title('Reports'),
    Route.create('offline', ReportsOffline)
      .name('reports.offline')
      .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ENGINEER)
      .title('Reports')
  ])
  .name('reports')

Route.add('/reports/create', ReportCreate)
  .name('reports.create')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ENGINEER)
  .title('Create a report')

Route.add('/reports/offline/:id/edit', ReportEdit)
  .name('reports.offline.edit')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ENGINEER)
  .title('Edit report')

Route.add('/reports/online/:id/edit', ReportView)
  .name('reports.online.edit')
  .middleware(MIDDLEWARE.AUTH)
  .title('Edit report')

Route.add('/users', Users)
  .name('users')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Users')

Route.add('/users/create', UserCreate)
  .name('users.create')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Create a user')

Route.add('/users/:id', UserEdit)
  .name('users.edit')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Edit user')

Route.add('/administrators', Administrators)
  .name('administrators')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Admins')

Route.add('/administrators/create', AdministratorCreate)
  .name('administrators.create')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Create an administrator')

Route.add('/administrators/:id', AdministratorEdit)
  .name('administrators.edit')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Edit administrators')

Route.add('/archive', Archive)
  .name('archive')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Archive')

Route.add('/archive/:id', ReportEdit)
  .name('archive.edit')
  .middleware(MIDDLEWARE.AUTH, MIDDLEWARE.ADMINISTRATOR)
  .title('Edit archived report')

Route.add('/unauthorized', NotAuthorized)
  .name('unauthorized')
  .middleware(MIDDLEWARE.AUTH)
  .title('403 unauthorized')

Route.add('/*', NotFound)
  .name('not-found')
  .title('404 not found')
