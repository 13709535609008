import Vue from 'vue'
import axios from 'axios'

const CancelToken = axios.CancelToken
let source = null
let requestTimeout

class Server {
  constructor () {
    this.vue = new Vue()

    const options = {
      onSuccess (response) {
        if (response.data.snackbar) {
          new Vue().$buefy.snackbar.open(Object.assign({
            duration: 5000,
            position: 'is-bottom-right',
            type: 'is-success',
            queue: true
          }, response.data.snackbar))
        }
      },
      onError (error) {
        if (error.response && error.response.data && error.response.data.snackbar) {
          new Vue().$buefy.snackbar.open(Object.assign({
            duration: 5000,
            position: 'is-bottom-right',
            type: 'is-danger',
            queue: true
          }, error.response.data.snackbar))
        } else {
          const errors = {
            DEFAULT: {
              message: 'An unexpected error occurred.'
            },
            VALIDATION: {
              code: 422,
              message: 'An error occurred while performing this action. Please review and submit again.'
            },
            UNAUTHORIZED: {
              code: 403,
              message: 'You are not authorized to perform this action.'
            },
            MAINTENANCE: {
              code: 503,
              message: 'The site is currently under maintenance. Please try again in a moment.'
            },
            EXPIRED: {
              code: 401,
              message: 'You need to log in to perform this action.'
            },
            INVALID_TOKEN: {
              code: 419,
              message: 'Your session has expired. Try reloading the page.'
            },
            INVALID_ROUTE: {
              code: 405,
              message: 'Invalid route.'
            },
            NOT_FOUND: {
              code: 404,
              message: 'This page does not exist.'
            }
          }

          let message = ''

          if (!error.response) {
            message = 'An unexpected error occurred.'
          } else {
            const key = Object.keys(errors).find(key => errors[key].code === error.response.status) || 'DEFAULT'

            const match = errors[key]

            message = match.message
          }

          new Vue().$buefy.snackbar.open({
            duration: 5000,
            position: 'is-bottom-right',
            type: 'is-danger',
            message,
            queue: true
          })
        }
      },
      options: {
        baseURL: process.env.VUE_APP_API_URL
      }
    }

    const axios = this.vue.$axios(options)

    const original = {
      get: axios.get,
      post: axios.post
    }

    axios.get = function (...params) {
      if (new Vue().$network.warnOffline()) {
        return
      }

      return original.get(...params)
    }

    axios.interceptors.request.use((config) => {
      if (this.vue.$auth.user) {
        Object.assign(config.headers, {
          Authorization: `Bearer ${this.vue.$auth.user.token}`
        })
      }

      // config.headers.ContentType = 'multipart/form-data'
      // Do something before request is sent
      return config
    }, function (error) {
      // Do something with request error
      return Promise.reject(error)
    })

    axios.withCredentials = true

    this.server = axios
  }

  isLogged () {
    return this.server.post('verify')
  }

  login (payload) {
    return this.server.post('sanctum/token', payload)
  }

  logout () {
    return this.server.post('/logout')
  }

  getDashboard () {
    return this.server.get('dashboard')
  }

  archiveReport (id) {
    return this.updateResource('report', {
      handler: 'archive',
      id
    })
  }

  unarchiveReport (id) {
    return this.updateResource('report', {
      handler: 'unarchive',
      id
    })
  }

  getResourceList (resource, params = { page: 1 }) {
    return this.server.get(`resource/${resource}/index`, {
      params
    })
  }

  createResource (resource, data) {
    return this.server.get(`resource/${resource}/create`, data)
  }

  storeResource (resource, data) {
    return this.server.post(`resource/${resource}/store`, data)
  }

  editResource (resource, params) {
    return this.server.get(`resource/${resource}/edit`, {
      params
    })
  }

  updateResource (resource, data, options) {
    return this.server.post(`resource/${resource}/update`, data, options)
  }

  destroyResource (resource, id) {
    return this.server.post(`resource/${resource}/destroy`, {
      id
    })
  }

  loadSignature (path) {
    return this.server.get(`${process.env.VUE_APP_BACKEND_URL}/dashboard/assets/${path}`)
  }

  async spaLogin () {
    return this.server.get('sanctum/csrf-cookie')
  }

  siteReports (id, params) {
    return this.server.get(`sites/${id}/reports`, {
      params
    })
  }

  siteBlocks (id, params) {
    return this.server.get(`sites/${id}/blocks`, {
      params
    })
  }

  searchResource (resource, params, options = {}) {
    clearTimeout(requestTimeout)

    source = CancelToken.source()

    return new Promise((resolve, reject) => {
      requestTimeout = setTimeout(() => {
        const mergedOptions = Object.assign({
          params
        }, options, {
          cancelToken: source.token
        })

        this.server.get(`resource/${resource}/search`, mergedOptions)
          .then(resolve)
          .catch(reject)
      }, 500)
    })
  }

  flagRead (id) {
    return this.server.post('notifications/update', {
      id
    })
  }

  getNotifications () {
    return this.server.get('notifications')
  }
}

export default {
  install (Vue) {
    Vue.prototype.$server = new Server()
  }
}
