<template>
  <section>
    <div class="content">
      <header class="list-header">
        <div class="add-padding">
          <ul>
            <li>
              <router-link :to="route('reports')">
                <i class="fas fa-chevron-left"></i>
                <span>Back</span>
              </router-link>
            </li>
            <li>Editing/Viewing report</li>
          </ul>
        </div>
      </header>
      <div class="admin-reports">
        <component v-if="template"
                   :is="template"
                   :report="form" :editable="$auth.isAdministrator"></component>
      </div>
    </div>
  </section>
</template>

<script>
import { templates } from '@/templates'

export default {
  name: 'ReportView',
  components: {
    ...templates
  },
  data () {
    return {
      report: null,
      form: {}
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    template () {
      if (!this.report) return

      return `${this.report.template.label.replace(/\s+/g, '')}`
    }
  },
  methods: {
    async load () {
      return this.$server.editResource('report', {
        id: this.$route.params.id
      })
        .then(response => {
          Object.assign(this.$data, response.data.vue)
        })
    }
  }
}
</script>
