<template>
  <div>
    <p>Error 403. Sorry you are not authorized to perform this action.</p>
    <router-link :to="route('login')" data-test="view-home">Back to home page</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotAuthorized'
}
</script>
