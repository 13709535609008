<template>
    <b-modal :active="$boolean.get('filter')">
        <div class="content">
            <header class="list-header">
                <h4>Filter reports</h4>
            </header>
            <form @submit.prevent="updateFilter" class="smaller-form engineer-forms">
                <b-field label="Select site"
                         v-bind="showError('site')">
                </b-field>
                <b-field label="Site"
                         v-bind="showError('site')">
                    <b-autocomplete v-model="value.site"
                                    :data="autocomplete.data"
                                    clearable
                                    :loading="autocomplete.loading"
                                    field="name"
                                    @typing="autocompleteField('site')"
                                    @blur="clearIfNotSelected('site')"></b-autocomplete>
                </b-field>
                <b-field label="Block"
                         v-bind="showError('block')">
                    <b-autocomplete v-model="value.block"
                                    :data="autocomplete.data"
                                    clearable
                                    :loading="autocomplete.loading"
                                    field="name"
                                    @typing="autocompleteField('block')"
                                    @blur="clearIfNotSelected('block')"></b-autocomplete>
                </b-field>
                <div class="columns is-multiline is-mobile">
                    <div class="column is-6">
                        <b-field label="Template"
                                 v-bind="showError('template')">
                            <b-select expanded v-model="value.template">
                                <option value="">Filter by template</option>
                                <option v-for="option in templates"
                                        :value="option.id"
                                        :key="option.id">{{ option.label }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <div class="Status"
                             :v-bind="showError('status')">
                            <b-field>Status</b-field>
                            <b-checkbox v-model="value.status"
                                        native-value="new">New
                            </b-checkbox>
                            <b-checkbox v-model="value.status"
                                        native-value="in-progress">In progress
                            </b-checkbox>
                            <b-checkbox v-model="value.status"
                                        native-value="completed">Complete
                            </b-checkbox>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="columns is-multiline">
                    <div class="column is-6">
                        <b-field label="Created by"
                                 v-bind="showError('assigned_by')">
                            <b-autocomplete v-model="value.assigned_by"
                                            :data="autocomplete.data"
                                            clearable
                                            :loading="autocomplete.loading"
                                            field="name"
                                            @typing="autocompleteField('assigned_by')"
                                            @blur="clearIfNotSelected('assigned_by')"></b-autocomplete>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Updated by"
                                 v-bind="showError('updatedBy')">
                            <b-autocomplete v-model="value.updatedBy"
                                            :data="autocomplete.data"
                                            clearable
                                            :loading="autocomplete.loading"
                                            field="name"
                                            @typing="autocompleteField('updated_by')"
                                            @blur="clearIfNotSelected('updated_by')"></b-autocomplete>
                        </b-field>
                    </div>
                </div>
                <div class="buttons is-right">
                    <b-button type="is-purple"
                              native-type="submit">
                        Apply
                    </b-button>
                    <b-button type="is-success"
                              @click="resetFilter">
                        Reset
                    </b-button>
                    <b-button @click="$boolean.set('filter', false)">
                        Cancel
                    </b-button>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
export default {
  name: 'ReportFilter',
  props: {
    value: {
      required: true,
      type: Object
    },
    templates: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      autocomplete: {
        data: [],
        loading: false
      }
    }
  },
  methods: {
    updateFilter () {
      this.$emit('update')
    },
    resetFilter () {
      this.$emit('reset')
    },
    autocompleteField (field) {
      this.autocomplete.loading = true

      const resources = {
        assigned_by: 'user-and-admin',
        updated_by: 'user-and-admin'
      }

      return this.$server.searchResource(resources[field] || field, this.value)
        .then(response => {
          this.autocomplete.loading = false

          this.autocomplete.data = response.data.suggestions
        })
        .catch(error => {
          this.autocomplete.loading = false

          throw error
        })
    },
    clearIfNotSelected (field) {
      if (!this.value[field]) {
        this.autocomplete[field] = null
      }
    }
  }
}
</script>
