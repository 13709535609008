export default {
  methods: {
    columnBlockName (props) {
      if (typeof props.row.block === 'string') {
        return props.row.block
      }

      return props.row.block ? props.row.block.name : 'N/A'
    },
    columnSiteName (props) {
      if (typeof props.row.site === 'string') {
        return props.row.site
      }

      return props.row.site ? props.row.site.name : 'N/A'
    }
  }
}
