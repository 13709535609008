export default {
  mounted () {
    if (!this.resource) {
      throw Error('Resource type undefined.')
    }
  },
  methods: {
    fetchData () {
      return this.$server.getResourceList(this.resource, this.filter)
    }
  }
}
