<template>
  <section>
    <div class="content">
      <header class="list-header">
        <div class="add-padding">
          <ul>
            <li>
              <router-link :to="route('reports')">
                <i class="fas fa-chevron-left"></i>
                <span>Back</span>
              </router-link>
            </li>
            <li>Editing/Viewing report</li>
          </ul>
        </div>
      </header>
      <div class="admin-reports">
        <component v-if="template"
                   :is="template"
                   :report="form"></component>
      </div>
    </div>
  </section>
</template>

<script>
import db from '@js/database'
import { templates } from '@js/templates'

const lockr = require('lockr')

export default {
  name: 'ReportEdit',
  components: {
    ...templates
  },
  data () {
    return {
      report: null,
      form: {}
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    template () {
      if (!this.report) return

      return `${this.report.template.label.replace(/\s+/g, '')}`
    }
  },
  methods: {
    async load () {
      await db.open()

      const query = await db.reports.where('id').equals(Number(this.$route.params.id)).toArray()

      const match = query[0]

      if (!match) {
        alert('This record is invalid.')
      }

      match.template = lockr.get('templates').find(template => template.label === match.template)

      this.report = match

      this.form = match
    }
  }
}
</script>
