<template>
  <nav class="navbar is-fixed-top">
    <template v-if="$auth.ready || $auth.isAdministrator">
      <div class="navbar-brand">
        <router-link
          class="navbar-item"
          :to="route('sites')"
        >
          <img src="/images/brand.svg"/>
        </router-link>
      </div>
      <div class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <button v-if="$auth.isEngineer" class="button" @click="$events.emit('reloadAssets', true)">Reload assets</button>
              <update-check></update-check>
              <p class="button is-white">
                <span class="mr-2">Status</span>
                <span class="tag is-success" v-if="$network.status">Online</span>
                <span class="tag is-danger" v-else>Offline</span>
              </p>
              <!-- <button class="button is-white"
                      v-if="$auth.user">
                <span class="icon"><i class="fas fa-lock"></i></span>
                <span>{{ $auth.user.first_name }}</span>
              </button> -->
              <button
                v-if="$auth.user"
                class="button is-white with-purple"
                type="button"
                data-test="action-logout"
                @click.prevent="$auth.logout"
              >
                <span class="icon"><i class="fas fa-power-off"></i></span>
                <span>Sign Out</span>
              </button>
              <router-link v-if="!$auth.user" :to="route('/')">Sign in</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </nav>
</template>
<script>
import UpdateCheck from '@components/UpdateCheck'

export default {
  components: {
    UpdateCheck
  }
}
</script>
