<template>
  <section>
    <div class="content">
      <header class="list-header">
        <div class="add-padding">
          <ul>
            <li>
              <router-link :to="route('sites')">
                <i class="fas fa-chevron-left"></i>
                <span>Back</span>
              </router-link>
            </li>
            <li><strong><span v-if="site">{{ site.name }}</span><span v-else>Loading...</span></strong></li>
          </ul>
        </div>
      </header>
      <div class="add-padding">
        <ul class="custom-tabs">
          <li>
            <router-link :to="route('sites.details', {id: $route.params.id})">Site Details</router-link>
          </li>
          <li>
            <router-link :to="route('sites.blocks', {id: $route.params.id})">Blocks</router-link>
          </li>
          <li>
            <router-link :to="route('sites.reports', {id: $route.params.id})">Reports</router-link>
          </li>
        </ul>
      </div>
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SiteEdit',
  data () {
    return {
      activeTab: '',
      site: null
    }
  },
  mounted () {
    return this.$server.editResource('site', {
      id: this.$route.params.id
    })
      .then(response => {
        this.site = response.data.vue.form
      })
  }
}
</script>
