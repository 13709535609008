export default {
  install (Vue) {
    Vue.mixin({
      computed: {
        backendUrl () {
          return process.env.VUE_APP_BACKEND_URL
        }
      },
      methods: {
        showError (field) {
          return {
            type: this.$errors.exists(field) ? 'is-danger' : '',
            message: this.$errors.exists(field) ? this.$errors.get(field) : ''
          }
        },
        route (name, params, query) {
          return {
            name,
            params,
            query
          }
        }
      }
    })
  }
}
