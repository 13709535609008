export function convertBeforePost (payload) {
  const data = new FormData()

  data.append('id', payload.id || '')

  data.append('handler', payload.handler)

  data.append('originalHandler', payload.originalHandler)

  data.append('action', payload.action)

  payload.images.forEach((image, index) => {
    data.append(`images[${index}]`, image)
  })

  if (payload.deletedAssets) {
    payload.deletedAssets.forEach((id, index) => {
      data.append(`deletedAssets[${index}]`, id)
    })
  }

  data.append('site', payload.site)

  data.append('template', payload.template)

  data.append('block', payload.block || '')

  data.append('materials', payload.materials || '')

  data.append('signature', payload.signature || '')

  if (payload.payload.readings) {
    payload.payload.readings.forEach((item, index) => {
      Object.keys(item).forEach(key => {
        data.append(`merge[readings][${index}][${key}]`, item[key] || '')
      })
    })
  }

  if (payload.payload.standard_lighting && payload.payload.standard_lighting.items) {
    payload.payload.standard_lighting.items.forEach((item, index) => {
      Object.keys(item).forEach(key => {
        data.append(`merge[standard_lighting][items][${index}][${key}]`, item[key] || '')
      })
    })
  }

  if (payload.payload.emergency_lighting && payload.payload.emergency_lighting.items) {
    payload.payload.emergency_lighting.items.forEach((item, index) => {
      Object.keys(item).forEach(key => {
        data.append(`merge[emergency_lighting][items][${index}][${key}]`, item[key] || '')
      })
    })
  }

  if (payload.payload.fire_alarm_system && payload.payload.fire_alarm_system.items) {
    payload.payload.fire_alarm_system.items.forEach((item, index) => {
      Object.keys(item).forEach(key => {
        data.append(`merge[fire_alarm_system][items][${index}][${key}]`, item[key] || '')
      })
    })
  }

  if (payload.payload.smoke_vent_system && payload.payload.smoke_vent_system.items) {
    payload.payload.smoke_vent_system.items.forEach((item, index) => {
      Object.keys(item).forEach(key => {
        data.append(`merge[smoke_vent_system][items][${index}][${key}]`, item[key] || '')
      })
    })
  }

  if (payload.payload.defects && payload.payload.defects.panel_photo && typeof payload.payload.defects.panel_photo !== 'string') {
    data.append('merge[defects][panel_photo]', payload.payload.defects.panel_photo || '')
    payload.payload.defects.panel_photo = null
  }

  data.append('payload', JSON.stringify(payload.payload))

  data.append('next_inspection_at', payload.next_inspection_at || '')

  data.append('created_at', payload.created_at)

  if (payload.created_by) {
    data.append('created_by', payload.created_by.owner.id)
  }

  return data
}
