<template>
  <div>
    <div class="add-padding">
      <hr>
      <h4>Battery Test Details:</h4>
      <b-field v-bind="showError('defects.battery_test_details')"></b-field>
      <b-table striped narrowed hoverable bordered :data="report.payload.defects.battery_test_details">
        <b-table-column label="Panel" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.index}.panel`)">
            <b-input type="text"
                     v-model="props.row.panel"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="ID" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.index}.id`)">
            <b-input type="text"
                     v-model="props.row.id"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="Make" v-slot="props">
          <combo-field
            label="Visit Type:"
            v-model="props.row.make"
            :name="`defects.battery_test_details.${props.index}.make`"
            :values="
            ['Yuasa', 'Yucel', 'Panasonic', 'Powersonic', 'Unknown'].map((value) => {
              return { value, label: value };
            })
          "
          ></combo-field>
        </b-table-column>
        <b-table-column label="Type" v-slot="props">
          <combo-field
            label="Type:"
            v-model="props.row.type"
            :name="`defects.battery_test_details.${props.index}.type`"
            :values="
            ['12 volt 2.1Ah', '12 volt 2.3Ah', '12 Volt 3.4Ah', '12 volt  5.0Ah', '12 volt 7.0Ah', '12 volt 12.0Ah', 'Board Mount'].map((value) => {
              return { value, label: value };
            })
          "
          ></combo-field>
        </b-table-column>
        <b-table-column label="Voltage" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.voltage}.id`)">
            <b-input type="text"
                     v-model="props.row.voltage"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="Reading" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.voltage}.reading`)">
            <b-input type="text"
                     v-model="props.row.reading"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="Status" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.voltage}.status`)">
            <b-input type="text"
                     v-model="props.row.status"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props">
          <div class="buttons is-right">
            <b-button type="is-danger" icon-right="trash"
                      @click="removeItem(report.payload.defects.battery_test_details, props.index)">
            </b-button>
          </div>
        </b-table-column>
        <template #empty>
          No battery test details available.
        </template>
      </b-table>
      <div class="buttons is-right mt-4">
        <b-button class="is-success" @click="addItem(report.payload.defects.battery_test_details,'BatteryTest')">
          Add Battery Test
        </b-button>
      </div>
      <hr>
      <div class="columns is-multiline is-mobile">
        <div class="column is-6">
          <fieldset>
            <b-field label="Charge Output 1" v-bind="showError('defects.charge_output_one')"></b-field>
            <b-input type="number" v-model="report.payload.defects.charge_output_one"></b-input>
          </fieldset>
        </div>
        <div class="column is-6">
          <fieldset>
            <b-field label="Charge Output 2" v-bind="showError('defects.charge_output_two')"></b-field>
            <b-input type="number" v-model="report.payload.defects.charge_output_two"></b-input>
          </fieldset>
        </div>
      </div>
      <fieldset>
        <b-field label="System Make" v-bind="showError('defects.system_make')">
          <b-input type="text"
                   v-model="report.payload.defects.system_make"></b-input>
        </b-field>
      </fieldset>
      <hr>
      <h4>Equipment Tested:</h4>
      <div class="columns is-multiline is-mobile">
        <div class="column is-6">
          <b-field label="Panels (Number)" v-bind="showError('defects.panels.number')">
            <b-input type="number"
                     v-model="report.payload.defects.panels.number"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <fieldset>
            <combo-field label="Status:"
                         v-model="report.payload.defects.panels.status"
                         name="defects.panels.status"
                         :values="['Pass', 'Fail', 'N/A'].map((value) => {
              return { value, label: value };
            })"
                         mode="radio">
            </combo-field>
          </fieldset>
        </div>
      </div>
      <div class="columns is-multiline is-mobile">
        <div class="column is-6">
          <b-field label="Batteries (Number)" v-bind="showError('defects.batteries.number')">
            <b-input type="number"
                     v-model="report.payload.defects.batteries.number"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <fieldset>
            <combo-field label="Status:"
                         v-model="report.payload.defects.batteries.status"
                         name="defects.batteries.status"
                         :values="['Pass', 'Fail', 'N/A'].map((value) => {
              return { value, label: value };
            })"
                         mode="radio">
            </combo-field>
          </fieldset>
        </div>
      </div>
      <div class="columns is-multiline is-mobile">
        <div class="column is-6">
          <b-field label="Door Holders (Number)" v-bind="showError('defects.door_holders.number')">
            <b-input type="number"
                     v-model="report.payload.defects.door_holders.number"></b-input>
          </b-field>
        </div>
        <div class="column  is-6">
          <fieldset>
            <combo-field label="Status:"
                         v-model="report.payload.defects.door_holders.status"
                         name="defects.door_holders.status"
                         :values="['Pass', 'Fail', 'N/A'].map((value) => {
              return { value, label: value };
            })"
                         mode="radio">
            </combo-field>
          </fieldset>
        </div>
      </div>
      <div class="columns is-multiline is-mobile">
        <div class="column is-6">
          <b-field label="Smoke Detectors (Number)" v-bind="showError('defects.smoke_detectors.number')">
            <b-input type="number"
                     v-model="report.payload.defects.smoke_detectors.number"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <fieldset>
            <combo-field label="Status:"
                         v-model="report.payload.defects.smoke_detectors.status"
                         name="defects.smoke_detectors.status"
                         :values="['Pass', 'Fail', 'N/A'].map((value) => {
              return { value, label: value };
            })"
                         mode="radio">
            </combo-field>
          </fieldset>
        </div>
      </div>
      <div class="columns is-multiline is-mobile">
        <div class="column is-6">
          <b-field label="Interface (Number)" v-bind="showError('defects.interface.number')">
            <b-input type="number"
                     v-model="report.payload.defects.interface.number"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <fieldset>
            <combo-field label="Status:"
                         v-model="report.payload.defects.interface.status"
                         name="defects.interface.status"
                         :values="['Pass', 'Fail', 'N/A'].map((value) => {
              return { value, label: value };
            })"
                         mode="radio">
            </combo-field>
          </fieldset>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-8">
          <b-field label="Other System Details" v-bind="showError('defects.other')">
            <b-input v-model="report.payload.defects.other"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <preview v-if="report.payload.defects.panel_photo" :file="typeof report.payload.defects.panel_photo === 'string' ? `${backendUrl}/dashboard/assets/${report.payload.defects.panel_photo}` : report.payload.defects.panel_photo" :key="report.payload.defects.panel_photo.lastModified" mode="thumbnail"></preview>
          <b-field v-bind="showError('defects.panel_photo')">
            <b-upload v-if="!report.payload.defects.panel_photo" expanded class="button is-success" v-model="report.payload.defects.panel_photo">
              Click to upload
            </b-upload>
            <b-button v-if="report.payload.defects.panel_photo" expanded class="button is-danger">Remove Upload</b-button>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportProps from '@js/mixins/ReportProps'
import ComboField from '@components/ComboField'
import Collection from '@/mixins/Collection'
import Preview from '@components/Preview'

export default {
  name: 'Defects',
  components: {
    ComboField,
    Preview
  },
  mixins: [ReportProps, Collection]
}
</script>
