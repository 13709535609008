<template>
  <div class="add-padding">
    <hr>
    <h4>Defective Luminaires</h4>
    <b-field v-bind="showError('defects.actions')"></b-field>

    <b-table striped narrowed hoverable bordered :data="report.payload.defects.actions">
      <b-table-column label="Lumi ID:" v-slot="props">
        <b-field v-bind="showError(`defects.actions.${props.index}.luminaireId`)">
          <b-input v-model="props.row.luminaireId"></b-input>
        </b-field>
      </b-table-column>
      <b-table-column label="Locations" v-slot="props">
        <combo-field
          label="Location"
          v-model="props.row.location"
          :name="`defects.actions.${props.index}.location`"
          :values="$options.$DROPDOWN.map('LOCATIONS')"
        ></combo-field>
      </b-table-column>
      <b-table-column label="Defect" v-slot="props">
        <combo-field
          :values="['Non functional', 'No supply', 'Battery flat', 'Damaged body', 'Missing', 'Out of date']
          .map(value => {return {value, label: value}})"
          :name="`defects.actions.${props.index}.type`"
          data-test="field-type"
          v-model="props.row.type"></combo-field>
      </b-table-column>
      <b-table-column v-slot="props">
        <div class="buttons is-right">
          <b-button type="is-danger" icon-right="trash"
                    @click="removeItem(report.payload.defects.actions, props.index)"></b-button>
        </div>
      </b-table-column>
      <template #empty>
        No defects listed.
      </template>
    </b-table>
    <div class="buttons is-right">
      <b-button type="is-success" @click="addItem(report.payload.defects.actions, 'LuminaireDefect')">
        Add row
      </b-button>
    </div>
    <hr/>
  </div>
</template>

<script>
import ReportProps from '@js/mixins/ReportProps'
import ComboField from '@components/ComboField'
import Collection from '@/mixins/Collection'
import $DROPDOWN from '@/dropdown'

export default {
  name: 'Defects',
  components: {
    ComboField
  },
  mixins: [ReportProps, Collection],
  $DROPDOWN
}
</script>
