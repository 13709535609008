const routes = []

export class Route {
  attributes = {
    path: null,
    name: null,
    component: null,
    components: null,
    children: [],
    meta: {}
  }

  constructor (path, component) {
    this.attributes.path = path

    if (component && component.render) {
      this.attributes.component = component
    } else if (component) {
      this.attributes.components = component
    }
  }

  name (name) {
    this.attributes.name = name

    return this
  }

  meta (key, value) {
    this.attributes.meta[key] = value

    return this
  }

  middleware (...params) {
    return this.meta('middleware', params)
  }

  title (title) {
    return this.meta('title', title)
  }

  children (routes) {
    this.attributes.children = this.attributes.children.concat(routes)

    return this
  }

  redirect (name) {
    this.attributes.redirect = typeof name === 'function' ? name : { name }

    return this
  }

  validate () {
    if (!this.attributes.path) {
      throw Error('No path defined')
    }

    if (!this.attributes.component && !this.attributes.components) {
      throw Error(`No component defined for path ${this.attributes.path}`)
    }

    if (!this.attributes.name) {
      throw Error('No name defined')
    }

    return this
  }

  toJson () {
    this.validate()

    const json = this.attributes

    if (json.children.length) {
      json.children = json.children.map(child => {
        return child.toJson()
      })
    }

    return json
  }

  static add (path, component) {
    const route = new Route(path, component)

    routes.push(route)

    return route
  }

  static create (path, component) {
    return new Route(path, component)
  }

  static all () {
    return routes.map(route => route.toJson())
  }
}
