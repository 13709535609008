<template>
  <div class="content">
    <div class="add-padding">
      <h1>Monthly Inspection</h1>
    </div>
    <div class="template-status add-padding">
      <div class="columns is-mobile is-multiline">
        <div class="column is-3">
          <p>
            <span class="mr-2">Status:</span>
            <span class="tag is-success" data-test="field-status">{{ form.status }}</span>
          </p>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit"
          class="smaller-form engineer-forms">
      <div class="add-padding">
        <h4>Inspection Details:</h4>
        <div class="columns is-align-items-center is-mobile is-multiline">
          <div class="column is-4">
            <label><strong>Next inspection date:</strong></label>
          </div>
          <div class="column is-8">
            <b-datepicker
              v-model="date.nextInspection"
              locale="en-GB"
              placeholder="Click to select..."
              @input="formatDate('nextInspection')"
              :date-formatter="dateFormatter"
              :close-on-click="true"
              trap-focus
            >
            </b-datepicker>
          </div>
        </div>
        <b-field v-bind="showError('next_inspection_at')"></b-field>
      </div>
      <div>
        <div class="add-padding">
          <h4>Standard Lighting:</h4>
          <fieldset>
            <radio-options label="Enable"
                           v-model="form.payload.standard_lighting.enable"
                           name="payload.standard_lighting.enable"
                           :options="['On', 'Off']"
                           @input="sectionChange($event, 'standard_lighting')"></radio-options>
          </fieldset>
          <div v-if="form.payload.standard_lighting.enable === 'On'">
            <fieldset>
              <b-field
                v-bind="showError('payload.standard_lighting.items')">
                <defects-list v-model="form.payload.standard_lighting.items"
                              defect-type="standard_lighting"
                              :report-id="form.id"></defects-list>
              </b-field>
            </fieldset>
            <fieldset>
              <b-field label="Findings and Recommendation"
                       v-bind="showError('payload.standard_lighting.recommendations')">
                <b-input type="textarea"
                         v-model="form.payload.standard_lighting.recommendations"></b-input>
                `
              </b-field>
            </fieldset>
          </div>
        </div>
        <hr/>
        <div class="add-padding">
          <h4>Emergency Lighting:</h4>
          <fieldset>
            <radio-options label="Enable"
                           v-model="form.payload.emergency_lighting.enable"
                           name="payload.emergency_lighting.enable"
                           :options="['On', 'Off']"
                           @input="sectionChange($event, 'emergency_lighting')"></radio-options>
          </fieldset>
          <div v-if="form.payload.emergency_lighting.enable === 'On'">
            <fieldset>
              <b-field
                v-bind="showError('payload.emergency_lighting.items')">
                <defects-list v-model="form.payload.emergency_lighting.items"
                              defect-type="emergency_lighting"
                              :report-id="form.id"></defects-list>
              </b-field>
            </fieldset>
            <fieldset>
              <b-field label="Findings and Recommendation"
                       v-bind="showError('payload.emergency_lighting.recommendations')">
                <b-input type="textarea"
                         v-model="form.payload.emergency_lighting.recommendations"></b-input>
              </b-field>
            </fieldset>
          </div>
        </div>
        <hr/>
        <div class="add-padding">
          <h4>Fire Alarm System:</h4>
          <fieldset>
            <radio-options label="Enable"
                           v-model="form.payload.fire_alarm_system.enable"
                           name="fire_alarm_system"
                           :options="['On', 'Off']"
                           @input="sectionChange($event, 'fire_alarm_system')"></radio-options>
          </fieldset>
          <div v-if="form.payload.fire_alarm_system.enable === 'On'">
            <fieldset>
              <radio-options label="Panel Lamp Test"
                             v-model="form.payload.fire_alarm_system.panel_lamp_test"
                             name="payload.fire_alarm_system.panel_lamp_test"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <radio-options label="Alarm Sounders Tested"
                             v-model="form.payload.fire_alarm_system.alarm_sounders_tested"
                             name="payload.fire_alarm_system.alarm_sounders_tested"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <radio-options label="Manual Call Points Tested"
                             v-model="form.payload.fire_alarm_system.manual_call_points_tested.status"
                             name="payload.fire_alarm_system.manual_call_points_tested.status"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <combo-field
                label="Location"
                v-model="form.payload.fire_alarm_system.manual_call_points_tested.location"
                name="fire_alarm_system.manual_call_points_tested.location"
                :values="$options.$DROPDOWN.map('LOCATIONS')"
              ></combo-field>
            </fieldset>
            <fieldset>
              <radio-options label="Any Detectors Tested"
                             v-model="form.payload.fire_alarm_system.detectors_tested.status"
                             name="payload.fire_alarm_system.detectors_tested.status"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <combo-field
                label="Location"
                v-model="form.payload.fire_alarm_system.detectors_tested.location"
                name="fire_alarm_system.detectors_tested.location"
                :values="$options.$DROPDOWN.map('LOCATIONS')"
              ></combo-field>
            </fieldset>
            <fieldset>
              <radio-options label="Is System Satisfactory?"
                             v-model="form.payload.fire_alarm_system.is_system_satisfactory"
                             name="payload.fire_alarm_system.is_system_satisfactory"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <radio-options label="Has Work and Faults been Recorded"
                             v-model="form.payload.fire_alarm_system.work_and_faults_recorded"
                             name="payload.fire_alarm_system.work_and_faults_recorded"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <b-field
                v-bind="showError('payload.fire_alarm_system.items')">
                <defects-list v-model="form.payload.fire_alarm_system.items"
                              defect-type="fire_alarm_system"
                              :report-id="form.id"></defects-list>
              </b-field>
            </fieldset>
            <fieldset>
              <b-field label="Findings and Recommendation"
                       v-bind="showError('payload.fire_alarm_system.recommendations')">
                <b-input type="textarea"
                         v-model="form.payload.fire_alarm_system.recommendations"></b-input>
              </b-field>
            </fieldset>
          </div>
        </div>
        <hr/>
        <div class="add-padding">
          <h4>Smoke Vent System:</h4>
          <fieldset>
            <radio-options label="Enable"
                           v-model="form.payload.smoke_vent_system.enable"
                           name="smoke_vent_system"
                           :options="['On', 'Off']"
                           @input="sectionChange($event, 'smoke_vent_system')"></radio-options>
          </fieldset>
          <div v-if="form.payload.smoke_vent_system.enable === 'On'">
            <fieldset>
              <radio-options label="Manual Call Points Tested"
                             v-model="form.payload.smoke_vent_system.manual_call_points_tested.status"
                             name="payload.smoke_vent_system.manual_call_points_tested.status"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <combo-field
                label="Location"
                v-model="form.payload.smoke_vent_system.manual_call_points_tested.location"
                name="smoke_vent_system.manual_call_points_tested.location"
                :values="$options.$DROPDOWN.map('LOCATIONS')"
              ></combo-field>
            </fieldset>
            <fieldset>
              <radio-options label="Is System Satisfactory"
                             v-model="form.payload.smoke_vent_system.is_system_satisfactory"
                             name="payload.smoke_vent_system.is_system_satisfactory"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <radio-options label="Work and Faults be Recorded"
                             v-model="form.payload.smoke_vent_system.work_and_faults_recorded"
                             name="payload.smoke_vent_system.work_and_faults_recorded"
                             :options="['Yes', 'No', 'N\\A']"></radio-options>
            </fieldset>
            <fieldset>
              <b-field
                v-bind="showError('payload.smoke_vent_system.items')">
                <defects-list v-model="form.payload.smoke_vent_system.items"
                              defect-type="smoke_vent_system"
                              :report-id="form.id"></defects-list>
              </b-field>
            </fieldset>
            <fieldset>
              <b-field label="Findings and Recommendation"
                       v-bind="showError('payload.smoke_vent_system.recommendations')">
                <b-input type="textarea"
                         v-model="form.payload.smoke_vent_system.recommendations"></b-input>
              </b-field>
            </fieldset>
          </div>
        </div>
        <reporting :original-report="report"
                   ref="reporting"
                   :report="form"
        ></reporting>
      </div>
      <div class="buttons main-buttons add-padding">
        <template v-if="editable">
          <b-button
            type="is-purple is-outlined"
            @click="save"
            :loading="isLoading"
            data-test="action-update"
          >
            Save
          </b-button>
          <b-button v-if="$auth.isAdministrator"
                    type="is-purple is-outlined"
                    @click="complete"
                    :loading="isLoading"
                    data-test="action-update"
          >
            Complete
          </b-button>
          <b-button
            type="is-success"
            :disabled="$network.isOffline"
            native-type="submit"
            :loading="isLoading" v-if="$route.name === 'reports.offline.edit'">
            Sync with Admin
          </b-button>
          <template v-else>
            <b-button type="is-success"
                      v-if="!form.quote_id"
                      :loading="isLoading"
                      @click="syncSimPro">Submit to SimPRO
            </b-button>
            <a :href="simProUrl()"
               v-else
               target="_blank">
              View on SimPro
            </a>
          </template>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import payload from './payload.json'
import ReportBase from '@/mixins/ReportBase'
import DefectsList from '@components/DefectsList'
import RadioOptions from '@components/RadioOptions'
import Reporting from '@components/Reporting'
import ComboField from '@components/ComboField'
import $DROPDOWN from '@/dropdown'

const components = {
  DefectsList,
  RadioOptions,
  Reporting,
  ComboField
}

const ResetSection = (section) => {
  return JSON.parse(JSON.stringify(payload))[section]
}

export default {
  name: 'Base',
  mixins: [
    ReportBase
  ],
  components,
  $DROPDOWN,
  methods: {
    originalPayload () {
      return payload
    },
    sectionChange (value, section) {
      if (value === 'Off') {
        this.form.payload[section] = new ResetSection(section)
      }
    },
    reportType () {
      return 'MonthlyInspection'
    }
  }
}
</script>
