<template>
  <b-autocomplete
    :data="data"
    :custom-formatter="customFormatter"
    :placeholder="placeholder"
    :loading="isFetching"
    :clear-on-select="clearOnSelect"
    :value="initial"
    @typing="fetch"
    @input="$emit('input', $event)"
    @select="$emit('select', $event)">
    <template slot-scope="props">
      <slot v-bind="props">
        {{ props }}
      </slot>
    </template>
  </b-autocomplete>
</template>

<script>
export default {
  name: 'AutocompleteField',
  props: {
    value: {
      type: String
    },
    clearOnSelect: {
      default: false
    },
    onTyping: {
      type: Function,
      required: true
    },
    placeholder: {},
    customFormatter: {
      type: Function,
      default (option) {
        return option
      }
    }
  },
  mounted () {
    this.initial = this.value
  },
  data () {
    return {
      isFetching: false,
      initial: '',
      data: []
    }
  },
  methods: {
    fetch (name) {
      return this.onTyping.bind(this)(name)
    },
    fetching () {
      this.isFetching = true
    },
    fetchDone () {
      this.isFetching = false
    },
    clearList () {
      this.data = []
    },
    setData (data) {
      this.data = data
    }
  }
}
</script>
