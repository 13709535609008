<template>
  <div>
    <header class="list-header">
      <div class="add-padding">
        <div class="columns is-mobile is-vcentered">
          <div class="column is-6 is-hidden-mobile">
            <p v-if="query.data.length">Displaying <span v-if="query.data.length">{{ query.offset }}-{{ query.count }}/{{
                query.total
              }} results</span></p>
          </div>
          <div class="column is-12-mobile is-6-desktop">
            <div class="buttons is-right">
              <b-button type="is-success is-outlined"
                        @click="$boolean.set('filter', true)">
                Filter
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <report-filter v-model="filter" :templates="templates" @update="updateFilter" @reset="resetFilter"></report-filter>
    <p v-if="noRecords">No records found</p>
    <b-table v-else
             v-bind="tableConfig"
             @page-change="goToPage"
             @sort="onSort">

      <!--  <b-table-column label="ID"
                          sortable>
              {{ props.row.id }}
          </b-table-column>-->

      <b-table-column label="Site"
                      sortable v-slot="props">
        {{ props.row.block.site.name }}
      </b-table-column>

      <b-table-column label="Block"
                      sortable v-slot="props">
        {{ props.row.block.name }}
      </b-table-column>

      <b-table-column label="Title"
                      sortable v-slot="props">
        {{ props.row.template.label }}
      </b-table-column>

      <b-table-column centered numeric label="Status"
                      sortable v-slot="props">
        <b-icon v-if="props.row.status === 'completed'" type="is-success"
                icon="check-circle"
                size="is-medium">
        </b-icon>
        <b-icon v-if="props.row.status === 'in-progress'" type="is-orange"
                icon="hourglass-start"
                size="is-medium">
        </b-icon>
        <b-icon v-if="props.row.status === 'new'" type="is-purple"
                icon="exclamation-circle"
                size="is-medium">
        </b-icon>
      </b-table-column>

      <b-table-column header-class="is-hidden-mobile" cell-class="is-hidden-mobile" label="Last updated"
                      sortable v-slot="props">
        {{ props.row.updated_at | uk_datetime }}
      </b-table-column>

      <b-table-column header-class="is-hidden-mobile" cell-class="is-hidden-mobile" label="Updated by"
                      sortable v-slot="props">
        {{ props.row.updated_by }}
      </b-table-column>

      <b-table-column header-class="is-hidden-mobile" cell-class="is-hidden-mobile" label="Assigned by"
                      sortable v-slot="props">
        {{ props.row.assigned_by }}
      </b-table-column>

      <b-table-column v-slot="props">
        <report-actions :report="props.row" @archive="archiveReport" @destroy="deleteRow"></report-actions>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
import LaravelTable from '@js/mixins/LaravelTable'
import ReportTable from '@js/mixins/ReportTable'
import ResourceLaravelTable from '@js/mixins/ResourceLaravelTable'
import ReportActions from '@components/ReportActions'
import ReportFilter from '@components/ReportFilter'

export default {
  name: 'SiteReports',
  components: {
    ReportActions,
    ReportFilter
  },
  mixins: [LaravelTable(), ResourceLaravelTable, ReportTable],
  data () {
    return {
      resource: 'report'
    }
  },
  methods: {
    updateUrl () {
      this.$router.push({
        name: 'sites.reports',
        query: this.filter,
        params: {
          id: this.$route.params.id
        }
      })
    },
    fetchData () {
      return this.$server.siteReports(this.$route.params.id, this.filter)
    }
  }
}
</script>
