import db from '@js/database'
import router from '@js/routing/router'

const lockr = require('lockr')

export default {
  install (Vue) {
    Vue.prototype.$auth = new Vue({
      data () {
        return {
          user: null,
          ready: false
        }
      },
      computed: {
        isAdministrator () {
          return this.user ? this.user.is_administrator : false
        },
        isEngineer () {
          return this.user ? this.user.is_engineer : false
        },
        redirectPath () {
          return this.isEngineer ? this.route('reports') : this.route('sites')
        }
      },
      methods: {
        isLogged () {
          return this.$server.isLogged()
            .then(response => {
              this.ready = true

              this.user = response.data.user
            })
        },
        logout () {
          // return this.$server.logout().then((response) => {
          //   this.$auth.ready = false
          //   this.$auth.user = null
          //
          //   // FixedHeader.removeClass()
          //   //
          //   // return VueRouter.router.push(this.route('root'))
          //   //   .then(() => {
          //   //     this.$auth.ready = true
          //   //   })
          // })

          return Promise.resolve()
            .then(() => {
              return db.sites.clear()
            })
            .then(() => {
              return db.materials.clear()
            })
            .then(() => {
              lockr.set('ready', false)
            })
            .then(() => {
              lockr.set('user', null)

              this.$auth.user = null

              this.$auth.ready = false

              return router.push({ name: 'login' })
            })
        }
      }
    })
  }
}
