<template>
  <div>
    <div v-if="mode=== 'original'">
      <img ref="image">
    </div>
    <div v-else>
      <img @click="$boolean.set(`showImage${id}`, true)" ref="image"
           style="cursor:pointer; max-width: 150px; max-height: 120px">
    </div>
    <b-modal :active="$boolean.get(`showImage${id}`)" @close="$boolean.set(`showImage${id}`, false)">
      <img v-if="$refs.image" :src="$refs.image.src">
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'Preview',
  props: {
    file: {
      required: true
    },
    mode: {
      type: String,
      default: 'original'
    }
  },
  data () {
    return {
      id: this._uid
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      if (!this.file) {
        throw Error('No valid file supplied.')
      }

      if (typeof this.file === 'string') {
        const $image = new Image()

        $image.onload = () => {
          this.$refs.image.src = this.file
        }

        $image.src = this.file

        return
      }

      const reader = new FileReader()

      reader.onload = (event) => {
        this.$refs.image.src = event.target.result
      }

      reader.readAsDataURL(this.file)
    }
  }
}
</script>
