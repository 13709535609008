<template>
  <div>
    <div class="add-padding wrapper-grey">
      <fieldset>
        <combo-field
          label="Auto Dialler Tested:"
          v-model="report.payload.inspection.auto_dialer_tested"
          name="inspection.auto_dialer_tested"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Panel Lamp Test:"
          v-model="report.payload.inspection.panel_lamp_test"
          name="inspection.panel_lamp_test"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Battery Fault Indication:"
          v-model="report.payload.inspection.battery_fault_indication"
          name="inspection.battery_fault_indication"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Zone Fault Indication:"
          v-model="report.payload.inspection.zone_fault_indication"
          name="inspection.zone_fault_indication"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Sounder Fault Indication:"
          v-model="report.payload.inspection.sounder_fault_indication"
          name="inspection.sounder_fault_indication"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
    </div>
    <div class="add-padding wrapper-grey">
      <h4>Covered During Inspection:</h4>
      <fieldset>
        <combo-field
          label="Log Book Available for Inspection:"
          v-model="report.payload.inspection.log_book_available"
          name="inspection.log_book_available"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Has Work and Faults be Recorded:"
          v-model="report.payload.inspection.has_work_and_faults_be_recorded"
          name="inspection.has_work_and_faults_be_recorded"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Equipment Listed Correctly:"
          v-model="report.payload.inspection.equipment_listed_correctly"
          name="inspection.equipment_listed_correctly"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Is there a Zone map:"
          v-model="report.payload.inspection.zone_map_available"
          name="inspection.zone_map_available"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Are the Zone mapped:"
          v-model="report.payload.inspection.zone_mapped"
          name="inspection.zone_mapped"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Any alterations to system:"
          v-model="report.payload.inspection.system_alterations"
          name="inspection.system_alterations"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Any alterations to building:"
          v-model="report.payload.inspection.building_alterations"
          name="inspection.building_alterations"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <b-field label="False alarms occurred during the past 12 months:" v-bind="showError('inspection.false_alarms_within_last_year')"></b-field>
        <b-input type="number"
                 v-model="report.payload.inspection.false_alarms_within_last_year"></b-input>
      </fieldset>
      <fieldset>
        <b-field label="The above number of false alarms equates to per 100 automatic fire detectors per annum:" v-bind="showError('inspection.false_alarms_per_automatic_fire_detectors_per_year')"></b-field>
        <b-input type="number"
                 v-model="report.payload.inspection.false_alarms_per_automatic_fire_detectors_per_year"></b-input>
      </fieldset>
    </div>
  </div>
</template>

<script>
import ReportProps from '@js/mixins/ReportProps'
import ComboField from '@components/ComboField'

export default {
  name: 'Inspection',
  components: {
    ComboField
  },
  mixins: [ReportProps]
}
</script>
