import ReportFilter from '@components/ReportFilter'
import { Filter } from '@js/mixins/LaravelTable'

export default {
  data () {
    return {
      templates: []
    }
  },
  components: {
    ReportFilter
  },
  methods: {
    archiveReport (id) {
      if (!confirm('Are you sure you want to archive this report?')) {
        return
      }

      return this.$server.archiveReport(id)
        .then(() => {
          return this.load()
        })
    },
    unarchiveReport (id) {
      if (!confirm('Are you sure you want to unarchive this report?')) {
        return
      }

      return this.$server.unarchiveReport(id)
        .then(() => {
          return this.load()
        })
    },
    filterConfig () {
      return new Filter({
        site: '',
        block: '',
        template: '',
        status: [],
        updated_by: null,
        assigned_by: null
      })
    }
  }
}
