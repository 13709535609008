<template>
  <div>
    <navbar></navbar>
    <main class="engineer">
      <nav class="main-navigation" v-if="$auth.ready">
        <ul>
          <li>
            <router-link :to="route('sites')"
                         data-test="view-sites">
              <i class="fas fa-building"></i>
              <span>Sites/Blocks</span>
            </router-link>
          </li>
          <li>
            <router-link :to="route('reports')"
                         data-test="view-reports">
              <i class="fas fa-chart-bar"></i>
              <span>Reports</span>
            </router-link>
          </li>
          <!--          <li>
                      <router-link :to="route('materials')">
                        <i class="fas fa-wrench"></i>
                        <span>Materials</span>
                      </router-link>
                    </li>-->
        </ul>
      </nav>
      <form-error name="load_assets"></form-error>
      <!--      <b-loading v-if="loadingAssets"
                       :is-full-page="true"
                       :active="true"></b-loading>-->
      <div v-if="loadingAssets" class="add-padding">
        <h4>{{ $assets.task }}</h4>
        <b-progress :value="$assets.current" type="is-success" :max="$assets.total" show-value format="percent"
                    size="is-large"></b-progress>
        <!-- <progress :value="$assets.current" :max="$assets.total" size="is-large"></progress> -->
        <button v-if="$errors.exists('load_assets')" class="button" @click="loadAssets(true)">
          Try again.
        </button>
      </div>
      <slot v-else></slot>
    </main>
  </div>
</template>

<script>
import loadAssets from '../api-assets'

export default {
  name: 'Engineer',
  data () {
    return {
      loadingAssets: true
    }
  },
  mounted () {
    this.loadAssets()

    this.$events.on('reloadAssets', this.loadAssets)
  },
  methods: {
    loadAssets (force = false) {
      this.loadingAssets = true

      this.$errors.clear()

      return loadAssets(force)
        .then(() => {
          this.loadingAssets = false
        })
        .catch(error => {
          const message = 'There was an error when loading the application assets. Please reload and try again.'

          this.$assets.task = message

          this.$errors.set('load_assets', message)

          throw error
        })
    }
  }
}
</script>
