<template>
  <div>
    <div class="add-padding">
      <hr>
      <h4>Battery Test Details:</h4>
      <b-field v-bind="showError('defects.battery_test_details')"></b-field>
      <b-table striped narrowed hoverable bordered  :data="report.payload.defects.battery_test_details">
        <b-table-column label="Panel" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.index}.panel`)">
            <b-input type="text"
                     v-model="props.row.panel"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="ID" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.index}.id`)">
            <b-input type="text"
                     v-model="props.row.id"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="Make" v-slot="props">
          <combo-field
            label="Visit Type:"
            v-model="props.row.make"
            :name="`defects.battery_test_details.${props.index}.make`"
            :values="
            ['Yuasa', 'Yucel', 'Panasonic', 'Powersonic', 'Unknown'].map((value) => {
              return { value, label: value };
            })
          "
          ></combo-field>
        </b-table-column>
        <b-table-column label="Type" v-slot="props">
          <combo-field
            label="Type:"
            v-model="props.row.type"
            :name="`defects.battery_test_details.${props.index}.type`"
            :values="
            ['12 volt 2.1Ah', '12 volt 2.3Ah', '12 Volt 3.4Ah', '12 volt  5.0Ah', '12 volt 7.0Ah', '12 volt 12.0Ah', 'Board Mount'].map((value) => {
              return { value, label: value };
            })
          "
          ></combo-field>
        </b-table-column>
        <b-table-column label="Voltage" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.voltage}.id`)">
            <b-input type="text"
                     v-model="props.row.voltage"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="Reading" v-slot="props">
          <b-field v-bind="showError(`defects.battery_test_details.${props.voltage}.reading`)">
            <b-input type="text"
                     v-model="props.row.reading"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="Status" v-slot="props">
          <combo-field v-model="props.row.status"
                       :name="`defects.battery_test_details.${props.voltage}.status`"
                       :values="['Pass', 'Fail', 'N/A'].map((value) => {
              return { value, label: value };
            })"
                       mode="radio">
          </combo-field>
        </b-table-column>
        <b-table-column v-slot="props">
          <div class="buttons is-right">
            <b-button @click="removeItem(report.payload.defects.battery_test_details, props.index)" icon-right="trash">
            </b-button>
          </div>
        </b-table-column>
        <template #empty>
          No battery test details available.
        </template>
      </b-table>
      <div class="buttons is-right">
        <b-button class="is-success" @click="addItem(report.payload.defects.battery_test_details, 'BatteryTest')">
          Add Battery Test
        </b-button>
      </div>
      <hr>
      <div class="columns is-multiline is-mobile">
        <div class="column is-6">
          <fieldset>
            <b-field label="Charge Output 1" v-bind="showError('defects.charge_output_one')"></b-field>
            <b-input type="number" v-model="report.payload.defects.charge_output_one"></b-input>
          </fieldset>
        </div>
        <div class="column is-6">
          <fieldset>
            <b-field label="Charge Output 2" v-bind="showError('defects.charge_output_two')"></b-field>
            <b-input type="number" v-model="report.payload.defects.charge_output_two"></b-input>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportProps from '@js/mixins/ReportProps'
import ComboField from '@components/ComboField'
import Collection from '@/mixins/Collection'

export default {
  name: 'Defects',
  components: {
    ComboField
  },
  mixins: [ReportProps, Collection]
}
</script>
