const SiteForm = () => {
  return {
    name: '',
    street1: '',
    street2: '',
    postcode: '',
    town: '',
    blocks: [],
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    property_manager_name: '',
    property_manager_company: '',
    property_manager_email: '',
    property_manager_phone: ''
  }
}

export default SiteForm
