<template>
  <div>
    <p v-if="originalReport.created_by">Inspected By: <strong>{{ originalReport.created_by.owner.name }}</strong>
    </p>
    <img :src="dataUrl" height="200px" crossorigin="anonymous" v-if="dataUrl">
    <div v-else>
      <b-field label="Signature"
               v-bind="showError('signature')"></b-field>
      <vueSignature ref="signature"
                    class="signature"
                    :h="'200px'"
                    :defaultUrl="dataUrl"
                    :disabled="disabled"></vueSignature>
      <hr/>
      <div class="buttons is-right">
        <b-button @click="clearSignature"
                  v-if="!disabled">Clear
        </b-button>
      </div>
      <hr/>
    </div>
  </div>
</template>

<script>
import VueSignature from 'vue-signature'

export default {
  props: {
    originalReport: {
      required: true
    },
    report: {
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    VueSignature
  },
  data () {
    return {
      dataUrl: ''
    }
  },
  created () {
    const signature = this.originalReport.assets.find(asset => asset.type === 'signature')
    const raw = this.originalReport.signature

    if (signature) {
      this.dataUrl = `${process.env.VUE_APP_BACKEND_URL}/dashboard/assets/${signature.path}`
    }

    if (raw) {
      this.dataUrl = raw
    }
  },
  methods: {
    get () {
      if (!this.$refs.signature) {
        return null
      }

      return this.$refs.signature.isEmpty() ? null : this.$refs.signature.save()
    },
    clearSignature () {
      this.$refs.signature.clear()
    }
  }
}
</script>
