<template>
  <main class="is-full-page is-centered">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-half">
          <header class="content has-text-centered">
            <h2 class="has-text-weight-normal">Login to your dashboard</h2>
          </header>
          <form @submit.prevent="login"
                class="card">
            <div class="card-content">
              <div class="field">
                <label class="label">Email <span class="has-text-primary">*</span></label>
                <div class="control has-icons-left">
                  <input class="input"
                         type="text"
                         v-model="form.email" data-test="field-email">
                  <span class="icon is-small is-left"><i class="fas fa-envelope"></i></span>
                </div>
                <form-error name="email"></form-error>
              </div>
              <div class="field">
                <label class="label">Password <span class="has-text-primary">*</span></label>
                <div class="control has-icons-left">
                  <input class="input"
                         type="password"
                         v-model="form.password" data-test="field-password">
                  <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
                </div>
                <form-error name="password"></form-error>
              </div>
              <!--              <div class="field has-text-right">
                              <a href="/password/reset"><small>Forgot password?</small></a>
                            </div>-->
              <div class="buttons is-centered">
                <b-button type="is-purple"
                          :loading="isLoading"
                          data-test="action-login"
                          native-type="submit">Login
                </b-button>
                <update-check></update-check>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import UpdateCheck from '@components/UpdateCheck'
import UAParser from 'ua-parser-js'

const Lockr = require('lockr')

const parser = new UAParser()

const device = parser.getDevice()

export default {
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: '',
        device_name: `${device.type} ${device.vendor} ${device.model}`
      }
    }
  },
  components: {
    UpdateCheck
  },
  methods: {
    login () {
      return this.$server.login(this.form)
        .then(response => {
          this.$auth.user = response.data.user

          Lockr.set('user', this.$auth.user)

          if (this.$auth.isAdministrator) {
            this.$auth.ready = true
          }

          return this.$router.push(this.$route.query.redirect || this.$auth.redirectPath)
        })
    }
  }
}
</script>
