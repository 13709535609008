const LaravelResourceForm = (config = {
  load: false,
  edit: false,
  requireClientValidation: false
}) => {
  return {
    data () {
      return {
        resource: null,
        ready: !config.load
      }
    },
    mounted () {
      if (!this.resource) {
        throw Error('Resource is not defined.')
      }

      if (!config.load) {
        return
      }

      if (this.$network.isOffline) {
        return this.loadFromOffline()
      }

      this.load()
    },
    methods: {
      loadFromOffline () {
        throw Error('No loadFromOffline function defined.')
      },
      load () {
        return Promise.resolve()
          .then(() => {
            if (config.edit) {
              return this.$server.editResource(this.resource, {
                id: this.$route.params.id
              })
            }

            return this.$server.createResource(this.resource)
          })
          .then(response => {
            return this.onLoadComplete(response)
          })
      },
      onLoadComplete (response) {
        Object.assign(this.$data, response.data.vue)

        this.ready = true
      },
      clientValidation () {
        throw Error('clientValidation function undefined.')
      },
      offlineSubmit () {
        throw Error('offlineSubmit function undefined.')
      },
      submit () {
        if (this.$network.isOffline) {
          return Promise.resolve()
            .then(() => {
              if (config.requireClientValidation) {
                return this.clientValidation()
              }
            })
            .then(() => {
              return this.offlineSubmit()
            })
        }

        return Promise.resolve()
          .then(() => {
            if (config.requireClientValidation) {
              return this.clientValidation()
            }
          })
          .then(() => {
            if (config.edit) {
              return this.$server.updateResource(this.resource, this.form)
            }

            return this.$server.storeResource(this.resource, this.form)
          })
          .then(response => {
            return this.onSubmitComplete(response)
          })
      },
      onSubmitComplete (response) {
        if (!config.edit) {
          this.resetForm()
        }

        if (this.redirectOnComplete) {
          return this.redirectOnComplete(response)
        }
      }
    }
  }
}

export default LaravelResourceForm
