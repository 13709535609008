import syncReport from '@/sync-report'
import { convertBeforePost } from '@/convert-form-data'
import db from '@/database'
import moment from 'moment'
import formats from '@/date-formats'
import { merge } from 'lodash'

export default {
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    report: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      date: {
        nextInspection: null
      },
      form: {}
    }
  },
  created () {
    const defaultPayload = JSON.parse(JSON.stringify(this.originalPayload()))
    const data = this.report.payload
      ? merge(defaultPayload, this.report.payload)
      : defaultPayload

    this.form = Object.assign({}, this.form, {
      next_inspection_at: this.report.next_inspection_at,
      payload: data,
      images: [],
      deletedAssets: []
    })

    this.date.nextInspection = this.report.next_inspection_at
      ? moment(this.report.next_inspection_at, formats.DB_DATE)._d
      : null
  },
  methods: {
    save () {
      return this.updateReport('new').then(() => {
        return this.redirectTo()
      })
    },
    complete () {
      return this.updateReport('complete').then(() => {
        return this.redirectTo()
      })
    },
    submit () {
      return this.updateReport('new', true).then(() => {
        return syncReport.bind(this)(this.report.id)
          .then(() => {
            return this.redirectTo()
          })
      })
    },
    redirectTo () {
      return this.$router.push(this.route(this.report.archived_at ? 'archive' : 'reports'))
    },
    getUpdateData (action) {
      const signature = this.$refs.reporting ? this.$refs.reporting.get() : null

      const payload = Object.assign(
        {
          id: this.report.id,
          handler: this.reportType(),
          action,
          signature
        },
        this.form
      )

      return convertBeforePost(payload)
    },
    onlineEdit (action) {
      // server update
      const data = this.getUpdateData(action)

      return this.$server.updateResource('report', data).then((response) => {
        Object.assign(this.form, response.data.report)

        return response
      })
    },
    offlineEdit (action, sync) {
      // indexDb update
      const assets = this.form.images.map(file => {
        return {
          type: 'raw-evidence',
          file
        }
      })

      const data = Object.assign({}, {
        handler: this.reportType(),
        action,
        payload: this.form.payload,
        assets,
        signature: this.$refs.reporting && this.$refs.reporting.get() ? this.$refs.reporting.get() : this.report.signature,
        next_inspection_at: this.form.next_inspection_at
      })

      return db.reports
        .update(Number(this.report.id), data)
        .then(updated => {
          if (updated && !sync) {
            return this.$buefy.snackbar.open({
              type: 'is-success',
              duration: 5000,
              position: 'is-bottom-right',
              message: 'Report updated successfully.',
              queue: true
            })
          }

          if (!updated) {
            return this.$buefy.snackbar.open({
              type: 'is-danger',
              duration: 5000,
              position: 'is-bottom-right',
              message: `Unable to update report with id ${this.report.id}.`,
              queue: true
            })
          }
        })
    },
    updateReport (action, sync = false) {
      if (this.$route.name === 'reports.online.edit') {
        return this.onlineEdit(action)
      }

      return this.offlineEdit(action, sync)
    },
    formatDate () {
      this.form.next_inspection_at = this.date.nextInspection
        ? moment(this.date.nextInspection).format('YYYY-MM-DD')
        : null
    },
    dateFormatter (value) {
      return moment(value).format('DD-MM-YYYY')
    },
    syncSimPro () {
      return this.updateReport('simpro')
        .then(() => {
          return this.redirectTo()
        })
    },
    simProUrl () {
      return `https://quantumgroupservices.simprosuite.com/staff/editCostCentre.php?quoteID=${this.report.quote_id}`
    },
    reportType () {
      throw Error('Report type not defined.')
    }
  }
}
