var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"template-status add-padding"},[_c('div',{staticClass:"columns is-mobile is-multiline"},[_c('div',{staticClass:"column is-3"},[_c('p',[_c('span',{staticClass:"mr-2"},[_vm._v("Status:")]),_c('span',{staticClass:"tag is-success",attrs:{"data-test":"field-status"}},[_vm._v(_vm._s(_vm.report.status))])])])])]),_c('form',{staticClass:"smaller-form engineer-forms",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"add-padding"},[_c('h4',[_vm._v("Inspection Details:")]),_c('div',{staticClass:"columns is-align-items-center is-mobile is-multiline"},[_vm._m(1),_c('div',{staticClass:"column is-8"},[_c('b-datepicker',{attrs:{"locale":"en-GB","placeholder":"Click to select...","date-formatter":_vm.dateFormatter,"close-on-click":true,"trap-focus":""},on:{"input":function($event){return _vm.formatDate('nextInspection')}},model:{value:(_vm.date.nextInspection),callback:function ($$v) {_vm.$set(_vm.date, "nextInspection", $$v)},expression:"date.nextInspection"}})],1)]),_c('b-field',_vm._b({},'b-field',_vm.showError('next_inspection_at'),false))],1),_c('div',{staticClass:"add-padding wrapper-grey"},[_c('fieldset',[_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['Single Swing', 'Twin Swing', 'Pedestrian Swing', 'Single Slider', 'Twin Slider', 'Barrier', 'Rising Bollard'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.gate_type),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection, "gate_type", $$v)},expression:"form.payload.inspection.gate_type"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Gate Use:","name":"inspection.gate_use","values":['Residential'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.gate_use),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection, "gate_use", $$v)},expression:"form.payload.inspection.gate_use"}})],1),_c('fieldset',[_c('b-field',_vm._b({attrs:{"label":"Environment Use"}},'b-field',_vm.showError('inspection.environment'),false),[_c('b-input',{model:{value:(_vm.form.payload.inspection.environment),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection, "environment", $$v)},expression:"form.payload.inspection.environment"}})],1)],1),_c('hr'),_c('fieldset',[_c('combo-field',{attrs:{"label":"Has Risk Assessment been carried out:","name":"inspection.risk_assessment","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.risk_assessment),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection, "risk_assessment", $$v)},expression:"form.payload.inspection.risk_assessment"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Has Impact Forces test been Carried out:","name":"inspection.impact_test","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.impact_test),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection, "impact_test", $$v)},expression:"form.payload.inspection.impact_test"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"CE Mark fixed to gate:","name":"inspection.ce_mark","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.ce_mark),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection, "ce_mark", $$v)},expression:"form.payload.inspection.ce_mark"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Power supply connected to isolator:","name":"inspection.power_supply","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.power_supply),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection, "power_supply", $$v)},expression:"form.payload.inspection.power_supply"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Manual release key & instructions provided:","name":"inspection.instructions","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.instructions),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection, "instructions", $$v)},expression:"form.payload.inspection.instructions"}})],1)]),_c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("Equipment As Installed:")]),_c('h5',{staticClass:"mt-5"},[_vm._v("Control Board")]),_c('b-table',{attrs:{"striped":"","narrowed":"","hoverable":"","bordered":"","data":_vm.form.payload.equipment.control_board},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" No records available. ")]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Make"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['FAAC', 'BFT', 'Aprimatic', 'Beninca', 'Nice'].map(function (value) {
            return { value: value, label: value };
          })},model:{value:(props.row.make),callback:function ($$v) {_vm.$set(props.row, "make", $$v)},expression:"props.row.make"}})]}}])}),_c('b-table-column',{attrs:{"label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['Photocell Wire', 'Photocell Battery', 'Ground Loop Entry', 'Ground Loop Exit', 'Ground Loop Safety', 'FB Drop Key', 'Visual Indication', 'Safety Edge Open', 'Safety Edge Close'].map(function (value) {
            return { value: value, label: value };
          })},model:{value:(props.row.type),callback:function ($$v) {_vm.$set(props.row, "type", $$v)},expression:"props.row.type"}})]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-danger","icon-right":"trash"},on:{"click":function($event){return _vm.removeItem(_vm.form.payload.equipment.control_board, props.index)}}})],1)]}}])})],1),_c('div',{staticClass:"buttons is-right mt-4"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.addItem(_vm.form.payload.equipment.control_board, 'ControlBoard')}}},[_vm._v(" Add row ")])],1),_c('h5',{staticClass:"mt-5"},[_vm._v("Operators")]),_c('b-table',{attrs:{"striped":"","narrowed":"","hoverable":"","bordered":"","data":_vm.form.payload.equipment.operators},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" No records available. ")]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Make"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['FAAC', 'BFT', 'Aprimatic', 'Beninca', 'Nice'].map(function (value) {
            return { value: value, label: value };
          })},model:{value:(props.row.make),callback:function ($$v) {_vm.$set(props.row, "make", $$v)},expression:"props.row.make"}})]}}])}),_c('b-table-column',{attrs:{"label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['Photocell Wire', 'Photocell Battery', 'Ground Loop Entry', 'Ground Loop Exit', 'Ground Loop Safety', 'FB Drop Key', 'Visual Indication', 'Safety Edge Open', 'Safety Edge Close'].map(function (value) {
            return { value: value, label: value };
          })},model:{value:(props.row.type),callback:function ($$v) {_vm.$set(props.row, "type", $$v)},expression:"props.row.type"}})]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-danger","icon-right":"trash"},on:{"click":function($event){return _vm.removeItem(_vm.form.payload.equipment.operators, props.index)}}})],1)]}}])})],1),_c('div',{staticClass:"buttons is-right mt-4"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.addItem(_vm.form.payload.equipment.operators, 'Operator')}}},[_vm._v("Add row")])],1),_c('h5',{staticClass:"mt-5"},[_vm._v("Safety: (Photocells / Ground Loops / Safety Edges/ FB drop key /Visual indication)")]),_c('b-table',{attrs:{"striped":"","narrowed":"","hoverable":"","bordered":"","data":_vm.form.payload.equipment.safety},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" No records available. ")]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Make"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['FAAC', 'BFT', 'Aprimatic', 'Beninca', 'Nice'].map(function (value) {
            return { value: value, label: value };
          })},model:{value:(props.row.make),callback:function ($$v) {_vm.$set(props.row, "make", $$v)},expression:"props.row.make"}})]}}])}),_c('b-table-column',{attrs:{"label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['Photocell Wire', 'Photocell Battery', 'Ground Loop Entry', 'Ground Loop Exit', 'Ground Loop Safety', 'FB Drop Key', 'Visual Indication', 'Safety Edge Open', 'Safety Edge Close'].map(function (value) {
            return { value: value, label: value };
          })},model:{value:(props.row.type),callback:function ($$v) {_vm.$set(props.row, "type", $$v)},expression:"props.row.type"}})]}}])}),_c('b-table-column',{attrs:{"label":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-input',{attrs:{"type":"number"},model:{value:(props.row.quantity),callback:function ($$v) {_vm.$set(props.row, "quantity", $$v)},expression:"props.row.quantity"}})]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-danger","icon-right":"trash"},on:{"click":function($event){return _vm.removeItem(_vm.form.payload.equipment.safety, props.index)}}})],1)]}}])})],1),_c('div',{staticClass:"buttons is-right mt-4"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.addItem(_vm.form.payload.equipment.safety, 'Safety')}}},[_vm._v(" Add row ")])],1),_c('h5',{staticClass:"mt-5"},[_vm._v("Control Components: (Remote Control /Keypad)")]),_c('b-table',{attrs:{"striped":"","narrowed":"","hoverable":"","bordered":"","data":_vm.form.payload.equipment.control_components},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" No records available. ")]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Make"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['FAAC', 'BFT', 'Aprimatic', 'Beninca', 'Nice'].map(function (value) {
            return { value: value, label: value };
          })},model:{value:(props.row.make),callback:function ($$v) {_vm.$set(props.row, "make", $$v)},expression:"props.row.make"}})]}}])}),_c('b-table-column',{attrs:{"label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('combo-field',{attrs:{"label":"Gate Type:","name":"inspection.gate_type","values":['Photocell Wire', 'Photocell Battery', 'Ground Loop Entry', 'Ground Loop Exit', 'Ground Loop Safety', 'FB Drop Key', 'Visual Indication', 'Safety Edge Open', 'Safety Edge Close'].map(function (value) {
            return { value: value, label: value };
          })},model:{value:(props.row.type),callback:function ($$v) {_vm.$set(props.row, "type", $$v)},expression:"props.row.type"}})]}}])}),_c('b-table-column',{attrs:{"label":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-input',{attrs:{"type":"number"},model:{value:(props.row.quantity),callback:function ($$v) {_vm.$set(props.row, "quantity", $$v)},expression:"props.row.quantity"}})]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-danger","icon-right":"trash"},on:{"click":function($event){return _vm.removeItem(_vm.form.payload.equipment.control_components, props.index)}}})],1)]}}])})],1),_c('div',{staticClass:"buttons is-right mt-4"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.addItem(_vm.form.payload.equipment.control_components, 'ControlComponent')}}},[_vm._v(" Add row ")])],1)],1),_c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("Gates:")]),_c('fieldset',[_c('combo-field',{attrs:{"label":"Gate design suitable:","name":"inspection.gates.design","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.gates.design),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.gates, "design", $$v)},expression:"form.payload.inspection.gates.design"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Gaps between hinge and post suitable:","name":"inspection.gates.hinge_gap","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.gates.hinge_gap),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.gates, "hinge_gap", $$v)},expression:"form.payload.inspection.gates.hinge_gap"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Gap between lower rail and roadway suitable:","name":"inspection.gates.rail_gap","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.gates.rail_gap),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.gates, "rail_gap", $$v)},expression:"form.payload.inspection.gates.rail_gap"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Do gates show any signs of damage or impact:","name":"inspection.gates.damage","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.gates.damage),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.gates, "damage", $$v)},expression:"form.payload.inspection.gates.damage"}})],1)]),_c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("Hinge Inspection:")]),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Hinges Suitable:","name":"inspection.hinge.suitable","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.hinge.suitable),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.hinge, "suitable", $$v)},expression:"form.payload.inspection.hinge.suitable"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Hinges In Suitable Condition:","name":"inspection.hinge.condition","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.hinge.condition),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.hinge, "condition", $$v)},expression:"form.payload.inspection.hinge.condition"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Hinges Inline:","name":"inspection.hinge.inline","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.hinge.inline),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.hinge, "inline", $$v)},expression:"form.payload.inspection.hinge.inline"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Have The Hinges Been Lubricated:","name":"inspection.hinge.lubricated","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.hinge.lubricated),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.hinge, "lubricated", $$v)},expression:"form.payload.inspection.hinge.lubricated"}})],1)]),_c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("Siding Inspection:")]),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is The Rack & Track In Suitable Condition:","name":"inspection.sliding.rack_condition","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.sliding.rack_condition),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.sliding, "rack_condition", $$v)},expression:"form.payload.inspection.sliding.rack_condition"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Running Wheels In Suitable Condition:","name":"inspection.sliding.wheels_condition","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.sliding.wheels_condition),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.sliding, "wheels_condition", $$v)},expression:"form.payload.inspection.sliding.wheels_condition"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is Track Free Of Debris:","name":"inspection.sliding.track","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.sliding.track),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.sliding, "track", $$v)},expression:"form.payload.inspection.sliding.track"}})],1)]),_c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("Other Points:")]),_c('fieldset',[_c('combo-field',{attrs:{"label":"Oil Levels Checked:","name":"inspection.other.oil_levels","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.other.oil_levels),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.other, "oil_levels", $$v)},expression:"form.payload.inspection.other.oil_levels"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are There Any Signs Of Fluid Loss:","name":"inspection.other.fluid_loss","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.other.fluid_loss),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.other, "fluid_loss", $$v)},expression:"form.payload.inspection.other.fluid_loss"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Underground Box Has Suitable Drainage:","name":"inspection.other.drainage","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.other.drainage),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.other, "drainage", $$v)},expression:"form.payload.inspection.other.drainage"}})],1)]),_c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("Control Panel:")]),_c('fieldset',[_c('combo-field',{attrs:{"label":"Control Enclosure Suitable:","name":"inspection.control_panel.enclosure","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.control_panel.enclosure),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.control_panel, "enclosure", $$v)},expression:"form.payload.inspection.control_panel.enclosure"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Enclosure Lockable:","name":"inspection.control_panel.lockable","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.control_panel.lockable),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.control_panel, "lockable", $$v)},expression:"form.payload.inspection.control_panel.lockable"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Signs Of Water Ingress:","name":"inspection.control_panel.water_ingress","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.control_panel.water_ingress),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.control_panel, "water_ingress", $$v)},expression:"form.payload.inspection.control_panel.water_ingress"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Cable Entry Sealed Ok:","name":"inspection.control_panel.cable_entry","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.control_panel.cable_entry),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.control_panel, "cable_entry", $$v)},expression:"form.payload.inspection.control_panel.cable_entry"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Cabling Identified:","name":"inspection.control_panel.cabling","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.control_panel.cabling),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.control_panel, "cabling", $$v)},expression:"form.payload.inspection.control_panel.cabling"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is cabling of correct type:","name":"inspection.control_panel.cabling_type","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.control_panel.cabling_type),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.control_panel, "cabling_type", $$v)},expression:"form.payload.inspection.control_panel.cabling_type"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Equipment Fixed In Cabinet Correctly:","name":"inspection.control_panel.cabinet","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.control_panel.cabinet),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.control_panel, "cabinet", $$v)},expression:"form.payload.inspection.control_panel.cabinet"}})],1)]),_c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("Safety:")]),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Photocells Installed:","name":"inspection.safety.photocells_installed","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.photocells_installed),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "photocells_installed", $$v)},expression:"form.payload.inspection.safety.photocells_installed"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Photocells Functioning Correctly:","name":"inspection.safety.functional","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.functional),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "functional", $$v)},expression:"form.payload.inspection.safety.functional"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Have Photocell Batteries Been Replaced:","name":"inspection.safety.batteries_replaced","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.batteries_replaced),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "batteries_replaced", $$v)},expression:"form.payload.inspection.safety.batteries_replaced"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Ground Loop Installed:","name":"inspection.safety.ground_loop","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.ground_loop),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "ground_loop", $$v)},expression:"form.payload.inspection.safety.ground_loop"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Ground Loops Functioning Correctly:","name":"inspection.safety.loop_functional","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.loop_functional),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "loop_functional", $$v)},expression:"form.payload.inspection.safety.loop_functional"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Safety Edges Installed:","name":"inspection.safety.safety_edges","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.safety_edges),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "safety_edges", $$v)},expression:"form.payload.inspection.safety.safety_edges"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Are Safety Edges Functioning Correctly:","name":"inspection.safety.edges_functional","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.edges_functional),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "edges_functional", $$v)},expression:"form.payload.inspection.safety.edges_functional"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Have Safety Edges Batteries Been Replaced:","name":"inspection.safety.edges_batteries_replaced","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.edges_batteries_replaced),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "edges_batteries_replaced", $$v)},expression:"form.payload.inspection.safety.edges_batteries_replaced"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is Visual Indication Installed:","name":"inspection.safety.visual_indication","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.visual_indication),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "visual_indication", $$v)},expression:"form.payload.inspection.safety.visual_indication"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is Visual Indication Functional:","name":"inspection.safety.indication_functional","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.indication_functional),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "indication_functional", $$v)},expression:"form.payload.inspection.safety.indication_functional"}})],1),_c('fieldset',[_c('combo-field',{attrs:{"label":"Is Signage Installed:","name":"inspection.safety.signage","values":['Yes', 'No', 'N/A'].map(function (value) {
            return { value: value, label: value };
          }),"mode":"radio"},model:{value:(_vm.form.payload.inspection.safety.signage),callback:function ($$v) {_vm.$set(_vm.form.payload.inspection.safety, "signage", $$v)},expression:"form.payload.inspection.safety.signage"}})],1)]),_vm._m(2),_c('evidence',{attrs:{"original-report":_vm.report,"report":_vm.form}}),_c('reporting',{ref:"reporting",attrs:{"original-report":_vm.report,"report":_vm.form}}),_c('quote',{attrs:{"original-report":_vm.report,"report":_vm.form}}),_c('div',{staticClass:"buttons main-buttons add-padding"},[(_vm.editable)?[_c('b-button',{attrs:{"type":"is-purple is-outlined","loading":_vm.isLoading,"data-test":"action-update"},on:{"click":_vm.save}},[_vm._v(" Save ")]),(_vm.$auth.isAdministrator)?_c('b-button',{attrs:{"type":"is-purple is-outlined","loading":_vm.isLoading,"data-test":"action-update"},on:{"click":_vm.complete}},[_vm._v(" Complete ")]):_vm._e(),(_vm.$route.name === 'reports.offline.edit')?_c('b-button',{attrs:{"type":"is-success","disabled":_vm.$network.isOffline,"native-type":"submit","loading":_vm.isLoading}},[_vm._v(" Sync with Admin ")]):[(!_vm.report.quote_id)?_c('b-button',{attrs:{"type":"is-success","loading":_vm.isLoading},on:{"click":_vm.syncSimPro}},[_vm._v("Submit to SimPRO ")]):_c('a',{attrs:{"href":_vm.simProUrl(),"target":"_blank"}},[_vm._v(" View on SimPro ")])]]:_vm._e()],2)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-padding"},[_c('h1',[_vm._v("Automated Gate")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-4"},[_c('label',[_c('strong',[_vm._v("Next inspection date:")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-padding"},[_c('hr'),_c('h4',[_vm._v("System Photos:")])])}]

export { render, staticRenderFns }