<template>
  <div class="content">
    <div class="add-padding">
      <h1>Meter Reading</h1>
    </div>
    <div class="template-status add-padding">
      <div class="columns is-mobile is-multiline">
        <div class="column is-3">
          <p>
            <span class="mr-2">Status:</span>
            <span class="tag is-success" data-test="field-status">{{ report.status }}</span>
          </p>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit"
          class="smaller-form engineer-forms">
      <div class="add-padding">
        <h4>Inspection Details:</h4>
        <div class="columns is-align-items-center is-mobile is-multiline">
          <div class="column is-4">
            <label><strong>Next inspection date:</strong></label>
          </div>
          <div class="column is-8">
            <b-datepicker
              v-model="date.nextInspection"
              locale="en-GB"
              placeholder="Click to select..."
              @input="formatDate('nextInspection')"
              :date-formatter="dateFormatter"
              :close-on-click="true"
              trap-focus
            >
            </b-datepicker>
          </div>
        </div>
        <b-field v-bind="showError('next_inspection_at')"></b-field>
      </div>

      <b-table :data="form.payload.readings">
        <b-table-column label="Block" v-slot="props">
          <b-field v-bind="showError(`readings.${props.index}.block`)">
            <autocomplete-field
              placeholder="Type a block name. If the block does not exists it will be created."
              v-bind="formatter().blocks"
              :value="props.row.block"
              @select="selectBlock($event, props.row)" @input="inputBlock($event, props.row)">
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    <strong>{{ props.option.name }}</strong>
                  </div>
                </div>
              </template>
            </autocomplete-field>
          </b-field>
        </b-table-column>
        <b-table-column label="Meter Number" v-slot="props">
          <b-field v-bind="showError(`readings.${props.index}.meter`)">
            <b-input v-model="props.row.meter"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="IPAN" v-slot="props">
          <b-field v-bind="showError(`readings.${props.index}.ipan`)">
            <b-input v-model="props.row.ipan"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column label="Reading" v-slot="props">
          <preview v-if="props.row.photo"
                   :file="typeof props.row.photo === 'string' ? `${backendUrl}/dashboard/assets/${props.row.photo}` : props.row.photo"
                   :key="props.row.photo.lastModified" mode="thumbnail"></preview>
          <b-field v-bind="showError(`readings.${props.index}.photo`)">
            <b-upload v-if="!props.row.photo" expanded class="button is-success" v-model="props.row.photo">
              Click to upload
            </b-upload>
            <b-button v-if="props.row.photo" expanded class="button is-danger">Remove Upload</b-button>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props">
          <div class="buttons is-right">
            <b-button type="is-danger" icon-right="trash"
                      @click="removeItem(form.payload.readings, props.index)"></b-button>
          </div>
        </b-table-column>
        <template #empty>
          No defects available.
        </template>
      </b-table>
      <div class="buttons is-right mt-4">
        <b-button type="is-success"
                  @click="addItem(form.payload.readings, 'MeterReading')"
                  :disabled="!editable">Add defect
        </b-button>
      </div>

      <quote :original-report="report"
             :report="form"></quote>
      <div class="buttons main-buttons add-padding">
        <template v-if="editable">
          <b-button
            type="is-purple is-outlined"
            @click="save"
            :loading="isLoading"
            data-test="action-update"
          >
            Save
          </b-button>
          <b-button v-if="$auth.isAdministrator"
                    type="is-purple is-outlined"
                    @click="complete"
                    :loading="isLoading"
                    data-test="action-update"
          >
            Complete
          </b-button>
          <b-button
            type="is-success"
            :disabled="$network.isOffline"
            native-type="submit"
            :loading="isLoading" v-if="$route.name === 'reports.offline.edit'">
            Sync with Admin
          </b-button>
          <template v-else>
            <b-button type="is-success"
                      v-if="!report.quote_id"
                      :loading="isLoading"
                      @click="syncSimPro">Submit to SimPRO
            </b-button>
            <a :href="simProUrl()"
               v-else
               target="_blank">
              View on SimPro
            </a>
          </template>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import payload from './payload.json'
import Quote from '@components/Quote'
import ReportBase from '@/mixins/ReportBase'
import Collection from '@/mixins/Collection'
import Preview from '@components/Preview'
import AutocompleteBlocks from '@/AutocompleteBlock'
import AutocompleteField from '@components/AutocompleteField'

const components = {
  Quote,
  Preview,
  AutocompleteField
}

export default {
  name: 'Base',
  mixins: [
    ReportBase,
    Collection
  ],
  components,
  methods: {
    originalPayload () {
      return payload
    },
    reportType () {
      return 'MeterReading'
    },
    formatter () {
      return {
        blocks: AutocompleteBlocks(this.report.site)
      }
    },
    selectBlock (value, row) {
      row.block = value.name
      console.log(value, row)
    },
    inputBlock (value, row) {
      row.block = value
      console.log(value, row)
    }
  }
}
</script>
