<template>
  <div>
    <b-field :label="label"
             v-bind="showError(name)">
      <b-radio-button v-model="query"
               :name="name"
               size="is-medium"
               :native-value="option"
               :disabled="disabled"
               v-for="(option, index) in options"
               :key="index"
                      :data-test="`field-${name}-${index}`"
               @input="update">
        {{ option }}
      </b-radio-button>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'RadioOptions',
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    value: {}
  },
  data () {
    return {
      query: null
    }
  },
  mounted () {
    this.query = this.value
  },
  methods: {
    update () {
      this.$emit('input', this.query)
      this.$errors.remove(this.name)
    }
  }
}
</script>
