export const FormAttributes = (attributes) => {
  return Object.assign({}, attributes)
}

const LaravelForm = {
  data () {
    return {
      form: this.formConfig()
    }
  },
  methods: {
    formConfig () {
      throw Error('formConfig method is not defined.')
    },
    submit () {
      throw Error('submit method is not defined.')
    },
    resetForm () {
      Object.assign(this.form, this.formConfig())
    }
  }
}

export default LaravelForm
