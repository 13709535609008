<template>
  <div>
    <div class="content">
      <header class="list-header">
        <div class="add-padding">
          <div class="columns is-vcentered">
            <div class="column is-6 is-hidden-mobile">
              <p>Displaying <span v-if="query.data.length">{{ query.offset }}-{{ query.count }} of {{ query.total }} Reports</span><span v-else>0 records.</span></p>
            </div>
            <div class="column is-6">
              <div class="buttons is-right">
                <router-link v-if="$auth.isEngineer"
                             :to="route('reports.create')"
                             data-test="action-create-report">
                  <b-button type="is-purple is-outlined">
                    Create report
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <b-table v-bind="tableConfig"
               @page-change="goToPage"
               @sort="onSort"
               :checked-rows.sync="checkedRows">

        <b-table-column label="Site"
                        v-slot="props"
                        sortable>
          {{ columnSiteName(props) }}
        </b-table-column>

        <b-table-column label="Block"
                        v-slot="props"
                        sortable>
          {{ columnBlockName(props) }}
        </b-table-column>

        <b-table-column label="Title"
                        v-slot="props"
                        sortable>
          {{ typeof props.row.template === 'string' ? props.row.template : props.row.template.label }}
        </b-table-column>

        <b-table-column centered
                        numeric
                        label="Status"
                        sortable>
          <b-icon type="is-purple"
                  icon="exclamation-circle"
                  size="is-medium">
          </b-icon>
        </b-table-column>
        <b-table-column v-slot="props" label="Created at">
          {{ props.row.created_at | uk_datetime }}
        </b-table-column>
        <b-table-column
          v-slot="props">
          <div class="buttons is-right are-rounded">
            <router-link class="button" :to="route('reports.offline.edit', {id: props.row.id})">
              <b-icon icon="edit"></b-icon>
            </router-link>
            <b-button
              :disabled="$network.isOffline"
              icon-right="sync" @click="sync(props.row.id)">
            </b-button>
            <b-button
              icon-right="trash" @click="deleteRow(props.row.id)">
            </b-button>
          </div>
        </b-table-column>
        <template #empty>
          No records available.
        </template>
      </b-table>
    </div>
    <report-filter v-model="filter"
                   :templates="templates"
                   @update="updateFilter"
                   @reset="resetFilter"></report-filter>
  </div>
</template>
<script>
import LaravelTable from '@js/mixins/LaravelTable'
import ReportTable from '@js/mixins/ReportTable'
import ResourceLaravelTable from '@js/mixins/ResourceLaravelTable'
import ReportFilter from '@components/ReportFilter'
import db from '@js/database'
import syncReport from '@/sync-report'

const qs = require('qs')

export default {
  name: 'Reports',
  components: {
    ReportFilter
  },
  mixins: [LaravelTable({ loadFromOffline: true }), ResourceLaravelTable, ReportTable],
  data () {
    return {
      resource: 'report',
      checkedRows: []
    }
  },
  computed: {
    previewBulkUpload () {
      const params = qs.stringify({
        reports: this.checkedRows.map(row => row.id),
        preview: 1
      })

      return `/dashboard/reports/export?${params}`
    },
    bulkExportUrl () {
      const params = qs.stringify({
        reports: this.checkedRows.map(row => row.id)
      })

      return `/dashboard/reports/export?${params}`
    }
  },
  methods: {
    updateUrl () {
      this.$router.push({
        name: 'reports',
        query: this.filter
      })
    },
    async offlineFetchData () {
      const pageNo = this.filter.page

      const pageSize = this.query.perPage

      return db.reports.count()
        .then(count => {
          return db.reports
            .reverse()
            .offset((pageNo - 1) * pageSize)
            .limit(pageSize)
            .toArray()
            .then(reports => {
              return {
                data: reports,
                total: count
              }
            })
        })
    },
    columnBlockName (props) {
      if (typeof props.row.block === 'string') {
        return props.row.block
      }

      return props.row.block ? props.row.block.name : 'N/A'
    },
    columnSiteName (props) {
      if (typeof props.row.site === 'string') {
        return props.row.site
      }

      return props.row.site ? props.row.site.name : 'N/A'
    },
    async sync (id) {
      return syncReport.bind(this)(id)
        .then(() => {
          return this.load()
        })
    },
    deleteRow (id) {
      if (!confirm('Are you sure you want to delete?')) {
        return
      }

      return db.reports.delete(id)
        .then(() => {
          return this.load()
        })
    }
  }
}
</script>
