<template>
  <div>
    <b-table :data="query">
      <b-table-column label="Note Defect/Issue" v-slot="props">
        <b-field v-bind="showError(`standard_lighting.items.${props.index}.defect`)">
          <b-input v-model="props.row.defect"></b-input>
        </b-field>
      </b-table-column>
      <b-table-column label="ID Number" v-slot="props">
        <b-field v-bind="showError(`standard_lighting.items.${props.index}.id`)">
          <b-input v-model="props.row.id"></b-input>
        </b-field>
      </b-table-column>
      <b-table-column label="Location" v-slot="props">
        <combo-field
          label="Location"
          v-model="props.row.location"
          :name="`standard_lighting.items.${props.index}.location`"
          :values="$options.$DROPDOWN.map('LOCATIONS')"
        ></combo-field>
      </b-table-column>
      <b-table-column label="Material Used" v-slot="props">
        <b-field v-bind="showError(`standard_lighting.items.${props.index}.material_used`)">
          <b-input v-model="props.row.material_used"></b-input>
        </b-field>
      </b-table-column>
      <b-table-column label="Material Required" v-slot="props">
        <b-field v-bind="showError(`standard_lighting.items.${props.index}.material_required`)">
          <b-input v-model="props.row.material_required"></b-input>
        </b-field>
      </b-table-column>
      <b-table-column label="Fitting Type/model" v-slot="props">
        <b-field v-bind="showError(`standard_lighting.items.${props.index}.model`)">
          <b-input v-model="props.row.model"></b-input>
        </b-field>
      </b-table-column>
      <b-table-column label="Photo" v-slot="props">
        <preview v-if="props.row.photo" :file="typeof props.row.photo === 'string' ? `${backendUrl}/dashboard/assets/${props.row.photo}` : props.row.photo" :key="props.row.photo.lastModified" mode="thumbnail"></preview>
        <b-field v-bind="showError(`standard_lighting.items.${props.index}.photo`)">
          <b-upload v-if="!props.row.photo" expanded class="button is-success" v-model="props.row.photo">
            Click to upload
          </b-upload>
          <b-button v-if="props.row.photo" expanded class="button is-danger">Remove Upload</b-button>
        </b-field>
      </b-table-column>
      <b-table-column v-slot="props">
        <div class="buttons is-right">
          <b-button type="is-danger" icon-right="trash" @click="removeItem(query, props.index)"></b-button>
        </div>
      </b-table-column>
      <template #empty>
        No defects available.
      </template>
    </b-table>
    <div class="buttons is-right mt-4">
      <b-button type="is-success"
                @click="addItem(query, 'MonthlyInspection')"
                :disabled="disabled">Add defect
      </b-button>
    </div>
  </div>
</template>

<script>
import Collection from '@/mixins/Collection'
import Preview from '@components/Preview'
import ComboField from '@components/ComboField'
import $DROPDOWN from '@/dropdown'

export default {
  props: {
    reportId: {
      required: true
    },
    value: {
      required: true
    },
    defectType: {
      required: true,
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  name: 'Defects',
  components: {
    Preview,
    ComboField
  },
  mixins: [
    Collection
  ],
  data () {
    return {
      query: []
    }
  },
  created () {
    this.query = this.value
  },
  $DROPDOWN
}
</script>
