<template>
    <div class="add-padding">
        <h4>Reporting:</h4>
        <b-field label="Variations/Report"
                 v-bind="showError('reporting.report')">
            <b-input type="textarea"
                     v-model="report.payload.reporting.report"
                     data-test="field-report">
            </b-input>
        </b-field>
        <b-field label="The Following Work/Action Is Considered Necessary"
                 v-bind="showError('reporting.necessary_action')">
            <b-input type="textarea"
                     v-model="report.payload.reporting.necessary_action"
                     data-test="field-necessary_action">
            </b-input>
        </b-field>
        <b-field label="Extent Of System Covered By This Certificate"
                 v-bind="showError('reporting.careplan')">
            <b-input type="textarea"
                     v-model="report.payload.reporting.careplan"
                     data-test="field-careplan">
            </b-input>
        </b-field>
        <report-signature ref="reportSignature"
                          :report="report"
                          :original-report="originalReport"></report-signature>
    </div>
</template>

<script>
import ReportSignature from '@components/ReportSignature'
import ReportProps from '@/mixins/ReportProps'

export default {
  name: 'Reporting',
  components: {
    ReportSignature
  },
  mixins: [ReportProps],
  methods: {
    get () {
      return this.$refs.reportSignature.get()
    }
    // validate () {
    //   const png = this.$refs.reportSignature.get()
    //
    //   return this.updateReport({
    //     signature: png
    //   })
    // },
    // nextStep () {
    //   return STEPS.EVIDENCE
    // }
  }
}
</script>
