<template>
  <div class="content">
    <div class="add-padding">
      <h1>Smoke Door</h1>
    </div>
    <div class="template-status add-padding">
      <div class="columns is-mobile is-multiline">
        <div class="column is-3">
          <p>
            <span class="mr-2">Status:</span>
            <span class="tag is-success" data-test="field-status">{{ report.status }}</span>
          </p>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit"
          class="smaller-form engineer-forms">
      <div class="add-padding">
        <h4>Inspection Details:</h4>
        <div class="columns is-align-items-center is-mobile is-multiline">
          <div class="column is-4">
            <label><strong>Next inspection date:</strong></label>
          </div>
          <div class="column is-8">
            <b-datepicker
              v-model="date.nextInspection"
              locale="en-GB"
              placeholder="Click to select..."
              @input="formatDate('nextInspection')"
              :date-formatter="dateFormatter"
              :close-on-click="true"
              trap-focus
            >
            </b-datepicker>
          </div>
        </div>
        <b-field v-bind="showError('next_inspection_at')"></b-field>
      </div>
      <inspection :original-report="report"
                  :report="form"></inspection>
      <defects :original-report="report"
               :report="form"></defects>
      <evidence :original-report="report"
                :report="form"></evidence>
      <reporting :original-report="report"
                 ref="reporting"
                 :report="form"
      ></reporting>
      <quote :original-report="report"
             :report="form"></quote>
      <div class="buttons main-buttons add-padding">
        <template v-if="editable">
          <b-button
            type="is-purple is-outlined"
            @click="save"
            :loading="isLoading"
            data-test="action-update"
          >
            Save
          </b-button>
          <b-button v-if="$auth.isAdministrator"
            type="is-purple is-outlined"
            @click="complete"
            :loading="isLoading"
            data-test="action-update"
          >
            Complete
          </b-button>
          <b-button
            type="is-success"
            :disabled="$network.isOffline"
            native-type="submit"
            :loading="isLoading" v-if="$route.name === 'reports.offline.edit'">
            Sync with Admin
          </b-button>
          <template v-else>
            <b-button type="is-success"
                      v-if="!report.quote_id"
                      :loading="isLoading"
                      @click="syncSimPro">Submit to SimPRO
            </b-button>
            <a :href="simProUrl()"
               v-else
               target="_blank">
              View on SimPro
            </a>
          </template>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import payload from './payload.json'
import Defects from '@js/templates/smoke-door/Defects'
import Evidence from '@components/Evidence'
import Inspection from '@js/templates/smoke-door/Inspection'
import Quote from '@components/Quote'
import Reporting from '@components/Reporting'
import ReportBase from '@/mixins/ReportBase'

const components = {
  Defects,
  Evidence,
  Inspection,
  Quote,
  Reporting
}

export default {
  name: 'Base',
  mixins: [
    ReportBase
  ],
  components,
  methods: {
    originalPayload () {
      return payload
    },
    reportType () {
      return 'SmokeDoor'
    }
  }
}
</script>
