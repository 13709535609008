<template>
  <div>
    <button class="button is-success" v-if="updateExists" @click="refreshApp">
      New version available! Click to update
    </button>
    <p class="button is-warning" v-else-if="refreshing">
      Updating...
    </p>
    <button class="button" v-else @click="manualRefresh">
      <span v-if="checking">Checking...</span>
      <span v-else>Check for update ({{ version }})</span>
    </button>
  </div>
</template>

<script>
const version = process.env.PACKAGE_VERSION || '0'

export default {
  data () {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      checking: false,
      state: 'Check for update',
      version
    }
  },
  created () {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    if (navigator.serviceWorker && process.env.NODE_ENV === 'production') {
      navigator.serviceWorker.addEventListener('controllerchange', this.controllerChange)
    }
  },
  beforeDestroy () {
    document.removeEventListener('swUpdated', this.showRefreshUI)

    if (navigator.serviceWorker && process.env.NODE_ENV === 'production') {
      navigator.serviceWorker.removeEventListener('controllerchange', this.controllerChange)
    }
  },
  methods: {
    showRefreshUI (event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false

      if (!this.registration || !this.registration.waiting) {
        return
      }

      this.registration.waiting.postMessage('skipWaiting')
    },
    manualRefresh () {
      if (this.$network.warnOffline()) {
        return Promise.reject(Error('An unexpected error occur.'))
      }

      return navigator.serviceWorker
        .getRegistration()
        .then(registration => {
          return registration.update()
        })
        .then(registration => {
          if (!registration.installing) {
            this.checking = false
            alert('No update found.')
          }
        })
        .catch(() => {
          this.checking = false
          alert('An unexpected error occurred.')
        })
    },
    controllerChange () {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    }
  }
}
</script>
