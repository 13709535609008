<template>
    <section>
        <div class="content">
            <header class="list-header">
                <div class="columns is-vcentered">
                    <div class="column is-6">
                        <p>Displaying <span v-if="query.data.length">{{ query.offset }}-{{ query.count}} of {{ query.total }} Users</span></p>
                    </div>
                    <div class="column is-6">
                        <div class="buttons is-right">
                            <b-button type="is-success"
                                      @click="$boolean.set('filter', true)">
                                Filter
                            </b-button>
                            <router-link :to="route('users.create')">
                                <b-button type="is-purple is-outlined">
                                    Add new User
                                </b-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </header>
            <b-modal :active="$boolean.get('filter')">
                <div class="content">
                    <header class="list-header">
                        <h4>Filter users</h4>
                    </header>
                    <form @submit.prevent="updateFilter" class="smaller-form">
                        <div class="columns is-multiline">
                            <div class="column is-4">
                                <b-field label="First name">
                                    <b-input type="text"
                                             v-model="filter.first_name">
                                    </b-input>
                                </b-field>
                            </div>
                            <div class="column is-4">
                                <b-field label="Email">
                                    <b-input type="text"
                                             v-model="filter.last_name">
                                    </b-input>
                                </b-field>
                            </div>
                            <div class="column is-4">
                                <b-field label="Email">
                                    <b-input type="text"
                                             v-model="filter.email">
                                    </b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="buttons is-right">
                            <b-button type="is-purple"
                                      native-type="submit">Apply
                            </b-button>
                            <b-button type="is-success"
                                      @click="resetFilter">Reset
                            </b-button>
                            <b-button @click="$boolean.set('filter', false)">
                                Cancel
                            </b-button>
                        </div>
                    </form>
                </div>
            </b-modal>

            <p v-if="noRecords">No records found</p>
            <b-table v-else
                     v-bind="tableConfig"
                     @page-change="goToPage"
                     @sort="onSort">

              <b-table-column v-slot="props" label="ID"
                              sortable>
                {{ props.row.id }}
              </b-table-column>

              <b-table-column v-slot="props" label="Name"
                              sortable>
                {{ props.row.name }}
              </b-table-column>

              <b-table-column v-slot="props" label="Email"
                              sortable>
                {{ props.row.email }}
              </b-table-column>

              <b-table-column v-slot="props">
                <div class="buttons is-right are-rounded">
                  <b-button type="open">
                    <router-link :to="route('users.edit', {id: props.row.id})">
                      <b-icon icon="eye"></b-icon>
                    </router-link>
                  </b-button>
                  <b-button @click="deleteRow(props.row.id)"
                            type="is-danger"
                            icon-right="fas fa-trash"/>
                </div>
              </b-table-column>
            </b-table>
        </div>
    </section>
</template>
<script>
import LaravelTable, { Filter } from '@js/mixins/LaravelTable'
import ResourceLaravelTable from '@js/mixins/ResourceLaravelTable'

export default {
  name: 'Users',
  mixins: [LaravelTable(), ResourceLaravelTable],
  data () {
    return {
      resource: 'user'
    }
  },
  methods: {
    updateUrl () {
      this.$router.push({
        name: 'users',
        query: this.filter
      })
    },
    filterConfig () {
      return new Filter({
        first_name: '',
        last_name: '',
        email: ''
      })
    }
  }
}
</script>
