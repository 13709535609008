import db from '@/database'

const fuzzysort = require('fuzzysort')

export default function AutocompleteBlocks (site) {
  return {
    onTyping (name) {
      if (!name.length) {
        this.clearList()
        return
      }

      if (this.$auth.isAdministrator) {
        return this.$server.searchResource('block', {
          site: site.name,
          block: name
        })
          .then(response => {
            this.setData(response.data.suggestions)
          })
          .catch((error) => {
            this.clearList()
            throw error
          })
          .finally(() => {
            this.fetchDone()
          })
      }

      this.fetching()

      if (!site) {
        return
      }

      return db.sites.where({ id: site.id })
        .toArray()
        .then(sites => {
          if (sites.length > 1) {
            throw Error('That is weird...')
          }

          const site = sites[0]

          if (site) {
            const blocks = fuzzysort.go(name, site.blocks, { key: 'name' })

            this.setData(blocks.map(({ obj }) => obj))

            return
          }

          this.setData([])
        })
        .catch((error) => {
          this.clearList()
          throw error
        })
        .finally(() => {
          this.fetchDone()
        })
    },
    customFormatter (site) {
      return site.name
    }
  }
}
