import AutomatedGate from './templates/automated-gate/Base'
import EmergencyLighting from './templates/emergency-lighting/Base'
import FireAlarm from './templates/fire-alarm/Base'
import MeterReading from './templates/meter-reading/Base'
import MonthlyInspection from './templates/monthly-inspection/Base'
import SmokeAlarm from './templates/smoke-alarm/Base'
import SmokeDoor from './templates/smoke-door/Base'
import SmokeVent from './templates/smoke-vent/Base'

import AutomatedGatePayload from './templates/automated-gate/payload.json'
import EmergencyLightingPayload from './templates/emergency-lighting/payload.json'
import FireAlarmPayload from './templates/fire-alarm/payload.json'
import MeterReadingPayload from './templates/meter-reading/payload.json'
import MonthlyInspectionPayload from './templates/monthly-inspection/payload.json'
import SmokeAlarmPayload from './templates/smoke-alarm/payload.json'
import SmokeDoorPayload from './templates/smoke-door/payload.json'
import SmokeVentPayload from './templates/smoke-vent/payload.json'

export const templates = {
  AutomatedGate,
  EmergencyLighting,
  FireAlarm,
  MeterReading,
  MonthlyInspection,
  SmokeDoor,
  SmokeAlarm,
  SmokeVent
}

export const payloads = {
  AutomatedGatePayload,
  EmergencyLightingPayload,
  FireAlarmPayload,
  MeterReadingPayload,
  MonthlyInspectionPayload,
  SmokeAlarmPayload,
  SmokeDoorPayload,
  SmokeVentPayload
}
