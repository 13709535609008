<template>
  <section>
    <div class="add-padding" v-if="$auth.isEngineer">
      <div class="buttons">
        <router-link :to="route('reports.online')" class="button">Online</router-link>
        <router-link :to="route('reports.offline')" class="button">Offline</router-link>
      </div>
    </div>
    <router-view></router-view>
  </section>
</template>
<script>
export default {}
</script>
