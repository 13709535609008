import db from '@/database'
import { convertBeforePost } from '@/convert-form-data'

const lockr = require('lockr')

function getSyncData (action, id) {
  const templates = lockr.get('templates')

  return db.reports.get(id)
    .then(report => {
      const payload = Object.assign({}, report, {
        handler: 'SyncOfflineReport',
        originalHandler: report.handler,
        action,
        images: report.assets.filter(item => item.type === 'raw-evidence')
          .map(item => item.file),
        site: report.site.id,
        template: templates.find(template => template.label === report.template).id,
        block: report.block,
        materials: report.materials,
        signature: report.signature
      })

      return convertBeforePost(payload)
    })
}

export default function syncReport (id) {
  return getSyncData('new', id)
    .then(data => {
      return this.$server.updateResource('report', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    })
    .then((response) => {
      return Promise.resolve()
        .then(() => {
          return db.sites.update(response.data.report.site.id, {
            blocks: response.data.blocks
          })
            .then(updated => {
              if (!updated) {
                alert('Something unexpected occurred. Please inform the administrator.')
              }
            })
        })
        .then(() => {
          return db.reports.delete(id)
        })
    })
}
