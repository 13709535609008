<template>
  <div>
    <div class="add-padding wrapper-grey">
      <fieldset>
        <combo-field
          label="Manual Test Button Tested:"
          v-model="report.payload.inspection.manual_test_button_tested"
          name="inspection.manual_test_button_tested"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Smoke Test Carried Out:"
          v-model="report.payload.inspection.smoke_test_carried_out"
          name="inspection.smoke_test_carried_out"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Battery Replaced:"
          v-model="report.payload.inspection.battery_replaced"
          name="inspection.battery_replaced"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Is Interlink Functional:"
          v-model="report.payload.inspection.is_interlink_functional"
          name="inspection.is_interlink_functional"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
      <fieldset>
        <combo-field
          label="Is System Satisfactory:"
          v-model="report.payload.inspection.is_system_satisfactory"
          name="inspection.is_system_satisfactory"
          :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
          mode="radio"
        ></combo-field>
      </fieldset>
    </div>
  </div>
</template>

<script>
import ReportProps from '@js/mixins/ReportProps'
import ComboField from '@components/ComboField'

export default {
  name: 'Inspection',
  components: {
    ComboField
  },
  mixins: [ReportProps]
}
</script>
