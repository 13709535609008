<template>
  <section>
    <div class="content">
      <header class="list-header">
        <div class="add-padding">
          <div class="columns is-vcentered">
            <div class="column is-6 is-hidden-mobile">
              <p>Displaying <span v-if="query.data.length">{{
                  query.offset
                }}-{{ query.count }} of {{ query.total }} Reports</span><span v-else>0 records.</span></p>
            </div>
            <div class="column is-6">
              <div class="buttons is-right">
                <b-button type="is-success"
                          @click="$boolean.set('filter', true)">
                  Filter sites
                </b-button>
                <router-link v-if="$auth.isEngineer"
                             :to="route('reports.create')"
                             data-test="action-create-report">
                  <b-button type="is-purple is-outlined">
                    Create report
                  </b-button>
                </router-link>
                <b-button type="is-purple is-outlined"
                          native-type="button"
                          :disabled="!checkedRows.length">
                  <a :href="previewBulkUpload"
                     target="_blank">Preview</a>
                </b-button>
                <b-button type="is-purple is-outlined"
                          native-type="button"
                          :disabled="!checkedRows.length">
                  <a :href="bulkExportUrl"
                     target="_blank">Export</a>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <b-table v-bind="tableConfig"
               @page-change="goToPage"
               @sort="onSort"
               :checked-rows.sync="checkedRows"
               :checkable="$auth.isAdministrator">
        <b-table-column label="Site" v-slot="props"
                        sortable>
          {{ columnSiteName(props) }}
        </b-table-column>

        <b-table-column label="Block" v-slot="props"
                        sortable>
          {{ columnBlockName(props) }}
        </b-table-column>

        <b-table-column label="Title" v-slot="props"
                        sortable>
          {{ typeof props.row.template === 'string' ? props.row.template : props.row.template.label }}
        </b-table-column>

        <b-table-column centered v-slot="props"
                        numeric
                        label="Status"
                        sortable>
          <b-icon v-if="props.row.status === 'completed'"
                  type="is-success"
                  icon="check-circle"
                  size="is-medium">
          </b-icon>
          <b-icon v-if="props.row.status === 'in-progress'"
                  type="is-orange"
                  icon="hourglass-start"
                  size="is-medium">
          </b-icon>
          <b-icon v-if="props.row.status === 'new'"
                  type="is-purple"
                  icon="exclamation-circle"
                  size="is-medium">
          </b-icon>
        </b-table-column>

        <b-table-column v-slot="props"
                        header-class="is-hidden-mobile"
                        cell-class="is-hidden-mobile"
                        label="Last updated"
                        sortable>
          {{ props.row.updated_at | uk_datetime }}
        </b-table-column>

        <b-table-column v-slot="props"
                        header-class="is-hidden-mobile"
                        cell-class="is-hidden-mobile"
                        label="Updated by"
                        sortable>
          {{ props.row.updated_by }}
        </b-table-column>

        <b-table-column v-slot="props"
                        header-class="is-hidden-mobile"
                        cell-class="is-hidden-mobile"
                        label="Assigned by"
                        sortable>
          {{ props.row.assigned_by }}
        </b-table-column>

        <b-table-column v-slot="props">
          <report-actions :report="props.row"
                          @archive="archiveReport"
                          @destroy="deleteRow"
                          @sync="sync"></report-actions>
        </b-table-column>
        <template #empty>
          No records available.
        </template>
      </b-table>
    </div>
    <report-filter v-model="filter"
                   :templates="templates"
                   @update="updateFilter"
                   @reset="resetFilter"></report-filter>
  </section>
</template>
<script>
import LaravelTable from '@js/mixins/LaravelTable'
import ReportTable from '@js/mixins/ReportTable'
import ResourceLaravelTable from '@js/mixins/ResourceLaravelTable'
import ReportActions from '@components/ReportActions'
import ReportFilter from '@components/ReportFilter'
import db from '@js/database'
import { convertBeforePost } from '@/convert-form-data'
import SiteAndBlockColumns from '@/mixins/SiteAndBlockColumns'

const qs = require('qs')

export default {
  name: 'Reports',
  components: {
    ReportActions,
    ReportFilter
  },
  mixins: [LaravelTable(), ResourceLaravelTable, ReportTable, SiteAndBlockColumns],
  data () {
    return {
      resource: 'report',
      checkedRows: []
    }
  },
  computed: {
    previewBulkUpload () {
      const params = qs.stringify({
        reports: this.checkedRows.map(row => row.id),
        preview: 1
      })

      return process.env.VUE_APP_BACKEND_URL + `/dashboard/reports/export?${params}`
    },
    bulkExportUrl () {
      const params = qs.stringify({
        reports: this.checkedRows.map(row => row.id)
      })

      return process.env.VUE_APP_BACKEND_URL + `/dashboard/reports/export?${params}`
    }
  },
  methods: {
    updateUrl () {
      this.$router.push({
        name: 'reports.online',
        query: this.filter
      })
    },
    async offlineFetchData () {
      return db.reports.toArray()
        .then(reports => {
          Object.assign(this.query, {
            data: reports,
            total: reports.length
          })
        })
    },
    async sync (id) {
      const match = await db.reports.get(Number(id))

      if (!match) {
        alert('This record is invalid.')
      }

      const deleteId = match.id

      match.template = window.Laravel.templates.find(template => template.label === match.template)

      match.images = match.assets.filter(asset => asset.type === 'raw-evidence').map(asset => asset.file)

      match.signature = match.assets.find(asset => asset.type === 'raw-signature').file

      match.version = match.template.latest_version

      match.template = match.template.id

      delete match.assets

      delete match.id

      const payload = Object.assign(match, {
        handler: 'SyncOfflineReport',
        originalHandler: match.handler
      })

      const data = convertBeforePost(payload)

      return this.$server.server.post('/api/admin/resource/report/update', data)
        .then(() => {
          return db.reports.delete(deleteId)
        })
        .then(() => {
          return this.load()
        })
    }
  }
}
</script>
