export const Handlers = {
  ControlBoard () {
    return {
      make: '',
      type: ''
    }
  },
  Operator () {
    return {
      make: '',
      type: ''
    }
  },
  Safety () {
    return {
      make: '',
      type: '',
      quantity: 1
    }
  },
  ControlComponent () {
    return {
      make: '',
      type: '',
      quantity: 1
    }
  },
  LuminaireDefect () {
    return {
      luminaireId: '',
      location: '',
      type: ''
    }
  },
  Defect () {
    return {
      location: '',
      type: '',
      action: ''
    }
  },
  BatteryTest () {
    return {
      panel: null,
      id: null,
      make: '',
      type: null,
      voltage: null,
      reading: null,
      status: null
    }
  },
  MonthlyInspection () {
    return {
      defect: null,
      id: null,
      location: null,
      material_used: null,
      material_required: null,
      model: null,
      photo: null
    }
  },
  MeterReading () {
    return {
      block: null,
      number: null,
      ipan: null,
      photo: null
    }
  }
}

export default {
  methods: {
    addItem (array, type) {
      array.push(new Handlers[type]())
    },
    removeItem (array, index) {
      array.splice(index, 1)
    }
  }
}
