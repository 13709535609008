import Vue from 'vue'
import moment from 'moment'
import App from './App.vue'
import './registerServiceWorker'
import router from '@js/routing/router'
import database from './database'
import VueHelpers from '@wyxos/vue-helpers'
import Buefy from 'buefy'
import auth from './auth'
import './scss/app.scss'
import Server from './server'
import Mixins from './mixins'
import Navbar from '@components/Navbar'
import formats from '@/date-formats'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

dom.watch()
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

window.db = database

Vue.config.productionTip = false

Vue.use(VueHelpers)
Vue.use(Mixins)
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})
Vue.use(auth)
Vue.use(Server)

Vue.use({
  install (Vue) {
    Vue.prototype.$assets = new Vue({
      data () {
        return {
          task: '',
          current: 0,
          total: 4
        }
      }
    })
  }
})

Vue.filter('uk_datetime', function (value) {
  if (!value) return 'N/A'
  return moment(value).format(formats.UK_DATETIME)
})

Vue.filter('uk_date', function (value) {
  if (!value) return 'N/A'
  return moment(value).format(formats.UK_DATE)
})

Vue.prototype.$network = new Vue({
  data () {
    return {
      status: navigator.onLine
    }
  },
  computed: {
    isOnline () {
      return Boolean(this.status)
    },
    isOffline () {
      return Boolean(!this.status)
    }
  },
  created () {
    window.addEventListener('offline', () => {
      this.status = false
    })
    window.addEventListener('online', () => {
      this.status = true
    })
  },
  methods: {
    warnOffline () {
      if (this.isOnline) {
        return false
      }

      alert('You must be online to perform this action.')
      // this.$buefy.snackbar.open({
      //   duration: 5000,
      //   position: 'is-bottom-right',
      //   type: 'is-danger',
      //   message: 'You must be online to perform this action.',
      //   queue: true
      // })

      return true
    }
  }
})

Vue.component('Navbar', Navbar)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
