<template>
  <section>
    <div class="content">
      <div class="add-padding">
        <header class="list-header">
          <ul>
            <li>
              <router-link :to="route('sites')">
                <i class="fas fa-chevron-left"></i>
                <span>Back</span>
              </router-link>
            </li>
            <li><strong>Create new a new report</strong></li>
          </ul>
        </header>
      </div>
      <div class="add-padding">
        <form @submit.prevent="submit"
              class="smaller-form">
          <div v-if="!site" class="field">
            <b-field label="Select site (Start typing to fetch data)"
                     v-bind="showError('site')">
              <autocomplete-field
                placeholder="Start typing a site name."
                v-bind="formatter().sites"
                @select="siteSelect">
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      <strong>{{ props.option.name }}</strong> {{ props.option.address }}
                    </div>
                  </div>
                </template>
              </autocomplete-field>
            </b-field>
          </div>
          <div v-else class="field">
            <p>{{ site.name }} {{ site.address }}</p>
          </div>

          <b-field label="Select block (Start typing to fetch data)"
                   v-bind="showError('block')">
            <autocomplete-field
              placeholder="Type a block name. If the block does not exists it will be created."
              v-bind="formatter().blocks"
              @select="blockSelect" @input="blockType">
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    <strong>{{ props.option.name }}</strong>
                  </div>
                </div>
              </template>
            </autocomplete-field>
          </b-field>

          <b-field v-bind="showError('template')"
                   label="Select Report"></b-field>
          <div class="columns is-multiline is-mobile has-full-radios">
            <div class="column is-6-mobile is-6"
                 v-for="template in templates"
                 :key="template.id">
              <b-radio v-model="form.template"
                       :native-value="template.label"
                       :data-test="`option-${template.slug}`">
                {{ template.label }}
              </b-radio>
            </div>
          </div>
          <div class="buttons is-right">
            <b-button native-type="submit"
                      type="is-purple"
                      :loading="isLoading"
                      data-test="action-create-report">
              Create
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import LaravelForm, { FormAttributes } from '../mixins/LaravelForm'
import LaravelResourceForm from '../mixins/LaravelResourceForm'
import db, { Report } from '../database'
import AutocompleteField from '../components/AutocompleteField'
import moment from 'moment'
import formats from '@/date-formats'
import AutocompleteBlocks from '@/AutocompleteBlock'
import { payloads } from '@/templates'
import { pascalCase } from 'change-case'
// import SmokeAlarmPayload from '@js/templates/smoke-alarm/v1.0.0/payload.json'

const lockr = require('lockr')

const AutocompleteSite = {
  onTyping (name) {
    if (!name.length) {
      this.clearList()
      return
    }

    this.fetching()

    if (this.$auth.isAdministrator) {
      return this.$server.searchResource('site', this.form)
        .then(response => {
          this.setData(response.data.suggestions)
        })
        .catch((error) => {
          this.clearList()
          throw error
        })
        .finally(() => {
          this.fetchDone()
        })
    }

    return db.sites.filter((site) => {
      return new RegExp(`${name}`, 'i').test(site.name)
    })
      .limit(40)
      .toArray()
      .then(sites => {
        this.setData(sites)
      })
      .catch((error) => {
        this.clearList()
        throw error
      })
      .finally(() => {
        this.fetchDone()
      })
  },
  customFormatter (site) {
    return site.name
  }
}

export default {
  name: 'ReportCreate',
  components: {
    AutocompleteField
  },
  mixins: [LaravelForm, LaravelResourceForm({ requireClientValidation: true })],
  data () {
    return {
      templates: lockr.get('templates'),
      resource: 'report',
      site: null
    }
  },
  mounted () {
    if (this.$route.query.site) {
      db.sites.get(Number(this.$route.query.site))
        .then(site => {
          this.site = site

          this.form.site = site.id
        })
    }
  },
  methods: {
    customFormatter (option) {
      return `${option.name}`
    },
    formConfig () {
      return new FormAttributes({
        site: null,
        block: null,
        template: null
      })
    },
    clientValidation () {
      this.$errors.clear()

      if (!this.form.site) {
        this.$errors.set('site', 'Site is required.')
      }

      if (!this.form.template) {
        this.$errors.set('template', 'Form is required.')
      }

      if (this.$errors.findBag('default').items.length) {
        throw Error('Validation error.')
      }
    },
    submit () {
      return Promise.resolve()
        .then(() => {
          return this.clientValidation()
        })
        .then(() => {
          return this.offlineSubmit()
        })
    },
    async offlineSubmit () {
      const template = this.templates.find(template => this.form.template === template.label)

      const payload = Object.assign({}, new Report(), this.form, {
        handler: template.label.replace(/\s+/g, ''),
        created_at: moment().format(formats.DB_DATETIME),
        payload: payloads[pascalCase(template.label) + 'Payload'],
        created_by: {
          owner: this.$auth.user
        }
      })

      console.log(payloads, template, pascalCase(template.label))

      const report = await db.reports.add(payload)

      return this.$router.push(this.route('reports.offline.edit', { id: report }))
    },
    formatter () {
      return {
        sites: AutocompleteSite,
        blocks: AutocompleteBlocks(this.form.site)
      }
    },
    siteSelect (site) {
      this.form.site = site
    },
    blockSelect (block) {
      this.form.block = block.name
    },
    blockType (block) {
      this.form.block = block
    }
  }
}
</script>
