<template>
  <div class="add-padding">
    <p v-if="!ready">Loading...</p>
    <form v-else
          @submit.prevent="submit" class="smaller-form">
      <div class="columns is-multiline">
        <div class="column is-4">
          <b-field label="Site Name:"
                   v-bind="showError('name')">
            <b-input type="text"
                     v-model="form.name" readonly disabled></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Street 1:"
                   v-bind="showError('street1')">
            <b-input type="text"
                     v-model="form.street1" readonly disabled></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Street 2:"
                   v-bind="showError('street2')">
            <b-input type="text"
                     v-model="form.street2" readonly disabled></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Town:"
                   v-bind="showError('town')">
            <b-input type="text"
                     v-model="form.town" readonly disabled></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Postcode:"
                   v-bind="showError('postcode')">
            <b-input type="text"
                     v-model="form.postcode" readonly disabled></b-input>
          </b-field>
        </div>
      </div>
      <hr>
      <header class="list-header">
        <h4>Contact Details</h4>
      </header>
      <div class="columns is-multiline">
        <div class="column is-4">
          <b-field label="Contact Name:"
                   v-bind="showError('contact_name')">
            <b-input type="text"
                     v-model="form.contact_name" v-bind="commonAttributes"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Contact Phone:"
                   v-bind="showError('contact_phone')">
            <b-input type="text"
                     v-model="form.contact_phone" v-bind="commonAttributes"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Contact Email:"
                   v-bind="showError('contact_email')">
            <b-input type="text"
                     v-model="form.contact_email" v-bind="commonAttributes"></b-input>
          </b-field>
        </div>
      </div>
      <hr>
      <header class="list-header">
        <h4>Property Management Details</h4>
      </header>
      <div class="columns is-multiline">
        <div class="column is-4">
          <b-field label="Property Manager Name:"
                   v-bind="showError('property_manager_name')">
            <b-input type="text"
                     v-model="form.property_manager_name" v-bind="commonAttributes"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Property Management Company:"
                   v-bind="showError('property_manager_company')">
            <b-input type="text"
                     v-model="form.property_manager_company" v-bind="commonAttributes"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Property Manager Email:"
                   v-bind="showError('property_manager_email')">
            <b-input type="text"
                     v-model="form.property_manager_email" v-bind="commonAttributes"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Property Manager Phone:"
                   v-bind="showError('property_manager_phone')">
            <b-input type="text"
                     v-model="form.property_manager_phone" v-bind="commonAttributes"></b-input>
          </b-field>
        </div>
      </div>
      <div class="buttons is-right">
        <b-button>
          <router-link :to="route('sites')">
            Back to list
          </router-link>
        </b-button>
        <b-button v-if="$auth.isAdministrator" type="is-purple" native-type="submit">Save</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import SiteForm from '@js/site-form'
import db from '@/database'

export default {
  name: 'SiteDetails',
  data () {
    return {
      form: new SiteForm(),
      ready: false
    }
  },
  mounted () {
    if (this.$auth.isEngineer) {
      return db.sites.get(Number(this.$route.params.id))
        .then(site => {
          Object.assign(this.form, site)

          this.ready = true
        })
    }

    return this.$server.editResource('site', {
      id: this.$route.params.id
    })
      .then(response => {
        Object.assign(this.$data, response.data.vue)

        this.ready = true
      })
  },
  computed: {
    commonAttributes () {
      const isEngineer = this.$auth.isEngineer

      return {
        disabled: isEngineer,
        readonly: isEngineer
      }
    }
  },
  methods: {
    submit () {
      return this.$server.updateResource('site', this.form)
        .then(response => {
          Object.assign(this.$data, response.data)
        })
    }
  }
}
</script>
