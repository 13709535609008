<template>
  <section>
    <div class="content">
      <header class="list-header">
        <div class="add-padding">
          <div class="columns is-vcentered">
            <div class="column is-6 is-hidden-mobile">
              <p>Displaying <span v-if="query.data.length">{{ query.offset }}-{{ query.count }} of {{ query.total }} sites/blocks</span>
              </p>
            </div>
            <div class="column is-6">
              <div class="buttons is-right">
                <b-button type="is-success"
                          @click="$boolean.set('filter', true)">
                  Filter sites
                </b-button>
<!--                <router-link v-if="$auth.isAdministrator" :to="route('sites.create')">
                  <b-button type="is-purple is-outlined">
                    Add new site
                  </b-button>
                </router-link>-->
              </div>
            </div>
          </div>
        </div>
      </header>
      <p v-if="noRecords" class="add-padding">No records found</p>
      <b-table v-else
               v-bind="tableConfig"
               @page-change="goToPage"
               @sort="onSort">

        <b-table-column label="ID"
                        v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column label="Site Name"
                        v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column label="Address"
                        v-slot="props">
          {{ props.row.address }}
        </b-table-column>

        <b-table-column label="Blocks"
                        v-slot="props">
          {{ blockCount(props.row) }}
        </b-table-column>

        <b-table-column label="Reports"
                        v-slot="props" v-if="$auth.isAdministrator">
          {{ reportCount(props.row) }}
        </b-table-column>

        <b-table-column v-slot="props">
          <div class="buttons is-right are-rounded">
            <router-link v-if="$auth.isEngineer" class="button" :to="{name: 'reports.create', query: {site: props.row.id}}">
              <b-icon icon="plus"></b-icon>
            </router-link>
            <b-button type="open">
              <router-link :to="route('sites.edit', {id: props.row.id})">
                <b-icon icon="eye"></b-icon>
              </router-link>
            </b-button>
          </div>
        </b-table-column>
      </b-table>
    </div>
    <b-modal :active="$boolean.get('filter')">
      <div class="content">
        <div class="filter-form">
          <header class="list-header">
            <h4>Filter sites</h4>
          </header>
          <form @submit.prevent="updateFilter" class="smaller-form">
            <div class="columns is-multiline">
              <div class="column is-6">
                <b-field label="Name">
                  <b-input type="text" size="is-small"
                           v-model="filter.name">
                  </b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field label="Postcode">
                  <b-input type="text" size="is-small"
                           v-model="filter.postcode">
                  </b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field label="Town">
                  <b-input type="text" size="is-small"
                           v-model="filter.town">
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="buttons is-right">
              <b-button type="is-purple"
                        native-type="submit">Apply
              </b-button>
              <b-button type="is-success"
                        @click="resetFilter">Reset
              </b-button>
              <b-button @click="$boolean.set('filter', false)">
                Cancel
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import LaravelTable, { Filter } from '@js/mixins/LaravelTable'
import db from '../database'
import ResourceLaravelTable from '@/mixins/ResourceLaravelTable'

export default {
  name: 'Sites',
  mixins: [LaravelTable(), ResourceLaravelTable],
  data () {
    return {
      resource: 'site'
    }
  },
  computed: {
    shouldLoadFromOffline () {
      return this.$auth.isEngineer
    }
  },
  methods: {
    getQuery () {
      const query = db.sites.reverse()

      if (this.filter.name) {
        query.filter((site) => {
          return new RegExp(`${this.filter.name}`, 'i').test(site.name)
        })
      }

      if (this.filter.postcode) {
        query.filter((site) => {
          return new RegExp(`${this.filter.postcode}`, 'i').test(site.postcode)
        })
      }

      if (this.filter.town) {
        query.filter((site) => {
          return new RegExp(`${this.filter.town}`, 'i').test(site.town)
        })
      }

      return query
    },
    offlineFetchData () {
      const pageNo = this.filter.page
      const pageSize = this.query.perPage

      return this.getQuery()
        .offset((pageNo - 1) * pageSize)
        .limit(pageSize)
        .toArray()
        .then(sites => {
          return this.getQuery().count()
            .then(count => {
              return {
                data: sites,
                total: count
              }
            })
        })
    },
    updateUrl () {
      const location = {
        name: 'sites',
        query: this.filter
      }

      return this.$router.push(location)
    },
    filterConfig () {
      return new Filter({
        name: '',
        postcode: '',
        town: ''
      })
    },
    blockCount (row) {
      return row.blocks_count !== undefined ? row.blocks_count : row.blocks.length
    },
    reportCount (row) {
      return row.reports_count !== undefined
        ? row.reports_count
        : row.reports.length
    }
  }
}
</script>
