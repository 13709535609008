<template>
  <div class="content">
    <div class="add-padding">
      <h1>Automated Gate</h1>
    </div>
    <div class="template-status add-padding">
      <div class="columns is-mobile is-multiline">
        <div class="column is-3">
          <p>
            <span class="mr-2">Status:</span>
            <span class="tag is-success" data-test="field-status">{{ report.status }}</span>
          </p>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit"
          class="smaller-form engineer-forms">
      <div class="add-padding">
        <h4>Inspection Details:</h4>
        <div class="columns is-align-items-center is-mobile is-multiline">
          <div class="column is-4">
            <label><strong>Next inspection date:</strong></label>
          </div>
          <div class="column is-8">
            <b-datepicker
              v-model="date.nextInspection"
              locale="en-GB"
              placeholder="Click to select..."
              @input="formatDate('nextInspection')"
              :date-formatter="dateFormatter"
              :close-on-click="true"
              trap-focus
            >
            </b-datepicker>
          </div>
        </div>
        <b-field v-bind="showError('next_inspection_at')"></b-field>
      </div>
      <div class="add-padding wrapper-grey">
        <fieldset>
          <combo-field
            label="Gate Type:"
            v-model="form.payload.inspection.gate_type"
            name="inspection.gate_type"
            :values="
            ['Single Swing', 'Twin Swing', 'Pedestrian Swing', 'Single Slider', 'Twin Slider', 'Barrier', 'Rising Bollard'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Gate Use:"
            v-model="form.payload.inspection.gate_use"
            name="inspection.gate_use"
            :values="
            ['Residential'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <b-field label="Environment Use" v-bind="showError('inspection.environment')">
            <b-input v-model="form.payload.inspection.environment"></b-input>
          </b-field>
        </fieldset>
        <hr>
        <fieldset>
          <combo-field
            label="Has Risk Assessment been carried out:"
            v-model="form.payload.inspection.risk_assessment"
            name="inspection.risk_assessment"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Has Impact Forces test been Carried out:"
            v-model="form.payload.inspection.impact_test"
            name="inspection.impact_test"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="CE Mark fixed to gate:"
            v-model="form.payload.inspection.ce_mark"
            name="inspection.ce_mark"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Power supply connected to isolator:"
            v-model="form.payload.inspection.power_supply"
            name="inspection.power_supply"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Manual release key & instructions provided:"
            v-model="form.payload.inspection.instructions"
            name="inspection.instructions"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
      </div>
      <div class="add-padding">
        <hr>
        <h4>Equipment As Installed:</h4>
        <h5 class="mt-5">Control Board</h5>
        <b-table striped narrowed hoverable bordered   :data="form.payload.equipment.control_board">
          <b-table-column label="Make" v-slot="props">
            <combo-field
              label="Gate Type:"
              v-model="props.row.make"
              name="inspection.gate_type"
              :values="
            ['FAAC', 'BFT', 'Aprimatic', 'Beninca', 'Nice'].map((value) => {
              return { value, label: value };
            })
          "
            ></combo-field>
          </b-table-column>
          <b-table-column label="Type" v-slot="props">
            <combo-field
              label="Gate Type:"
              v-model="props.row.type"
              name="inspection.gate_type"
              :values="
            ['Photocell Wire', 'Photocell Battery', 'Ground Loop Entry', 'Ground Loop Exit', 'Ground Loop Safety', 'FB Drop Key', 'Visual Indication', 'Safety Edge Open', 'Safety Edge Close'].map((value) => {
              return { value, label: value };
            })
          "
            ></combo-field>
          </b-table-column>
          <b-table-column v-slot="props">
            <div class="buttons is-right">
              <b-button type="is-danger" icon-right="trash"
                        @click="removeItem(form.payload.equipment.control_board, props.index)"></b-button>
            </div>
          </b-table-column>
          <template #empty>
            No records available.
          </template>
        </b-table>
        <div class="buttons is-right mt-4">
          <b-button type="is-success" @click="addItem(form.payload.equipment.control_board, 'ControlBoard')">
            Add row
          </b-button>
        </div>

        <h5 class="mt-5">Operators</h5>
        <b-table striped narrowed hoverable bordered   :data="form.payload.equipment.operators">
          <b-table-column label="Make" v-slot="props">
            <combo-field
              label="Gate Type:"
              v-model="props.row.make"
              name="inspection.gate_type"
              :values="
            ['FAAC', 'BFT', 'Aprimatic', 'Beninca', 'Nice'].map((value) => {
              return { value, label: value };
            })
          "
            ></combo-field>
          </b-table-column>
          <b-table-column label="Type" v-slot="props">
            <combo-field
              label="Gate Type:"
              v-model="props.row.type"
              name="inspection.gate_type"
              :values="
            ['Photocell Wire', 'Photocell Battery', 'Ground Loop Entry', 'Ground Loop Exit', 'Ground Loop Safety', 'FB Drop Key', 'Visual Indication', 'Safety Edge Open', 'Safety Edge Close'].map((value) => {
              return { value, label: value };
            })
          "
            ></combo-field>
          </b-table-column>
          <b-table-column v-slot="props">
            <div class="buttons is-right">
              <b-button type="is-danger" icon-right="trash" @click="removeItem(form.payload.equipment.operators, props.index)"></b-button>
            </div>
          </b-table-column>
          <template #empty>
            No records available.
          </template>
        </b-table>
        <div class="buttons is-right mt-4">
          <b-button type="is-success" @click="addItem(form.payload.equipment.operators, 'Operator')">Add row</b-button>
        </div>

        <h5 class="mt-5">Safety: (Photocells / Ground Loops / Safety Edges/ FB drop key /Visual indication)</h5>
        <b-table striped narrowed hoverable bordered   :data="form.payload.equipment.safety">
          <b-table-column label="Make" v-slot="props">
            <combo-field
              label="Gate Type:"
              v-model="props.row.make"
              name="inspection.gate_type"
              :values="
            ['FAAC', 'BFT', 'Aprimatic', 'Beninca', 'Nice'].map((value) => {
              return { value, label: value };
            })
          "
            ></combo-field>
          </b-table-column>
          <b-table-column label="Type" v-slot="props">
            <combo-field
              label="Gate Type:"
              v-model="props.row.type"
              name="inspection.gate_type"
              :values="
            ['Photocell Wire', 'Photocell Battery', 'Ground Loop Entry', 'Ground Loop Exit', 'Ground Loop Safety', 'FB Drop Key', 'Visual Indication', 'Safety Edge Open', 'Safety Edge Close'].map((value) => {
              return { value, label: value };
            })
          "
            ></combo-field>
          </b-table-column>
          <b-table-column label="Quantity" v-slot="props">
            <b-input type="number" v-model="props.row.quantity"></b-input>
          </b-table-column>
          <b-table-column v-slot="props">
            <div class="buttons is-right">
              <b-button type="is-danger" icon-right="trash" @click="removeItem(form.payload.equipment.safety, props.index)"></b-button>
            </div>
          </b-table-column>
          <template #empty>
            No records available.
          </template>
        </b-table>
        <div class="buttons is-right mt-4">
          <b-button type="is-success" @click="addItem(form.payload.equipment.safety, 'Safety')">
            Add row
          </b-button>
        </div>

        <h5 class="mt-5">Control Components: (Remote Control /Keypad)</h5>
        <b-table striped narrowed hoverable bordered   :data="form.payload.equipment.control_components">
          <b-table-column label="Make" v-slot="props">
            <combo-field
              label="Gate Type:"
              v-model="props.row.make"
              name="inspection.gate_type"
              :values="
            ['FAAC', 'BFT', 'Aprimatic', 'Beninca', 'Nice'].map((value) => {
              return { value, label: value };
            })
          "
            ></combo-field>
          </b-table-column>
          <b-table-column label="Type" v-slot="props">
            <combo-field
              label="Gate Type:"
              v-model="props.row.type"
              name="inspection.gate_type"
              :values="
            ['Photocell Wire', 'Photocell Battery', 'Ground Loop Entry', 'Ground Loop Exit', 'Ground Loop Safety', 'FB Drop Key', 'Visual Indication', 'Safety Edge Open', 'Safety Edge Close'].map((value) => {
              return { value, label: value };
            })
          "
            ></combo-field>
          </b-table-column>
          <b-table-column label="Quantity" v-slot="props">
            <b-input type="number" v-model="props.row.quantity"></b-input>
          </b-table-column>
          <b-table-column v-slot="props">
            <div class="buttons is-right">
              <b-button type="is-danger" icon-right="trash"
                        @click="removeItem(form.payload.equipment.control_components, props.index)"></b-button>
            </div>
          </b-table-column>
          <template #empty>
            No records available.
          </template>
        </b-table>
        <div class="buttons is-right mt-4">
          <b-button type="is-success" @click="addItem(form.payload.equipment.control_components, 'ControlComponent')">
            Add row
          </b-button>
        </div>
      </div>
      <div class="add-padding">
        <hr>
        <h4>Gates:</h4>
        <fieldset>
          <combo-field
            label="Gate design suitable:"
            v-model="form.payload.inspection.gates.design"
            name="inspection.gates.design"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Gaps between hinge and post suitable:"
            v-model="form.payload.inspection.gates.hinge_gap"
            name="inspection.gates.hinge_gap"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Gap between lower rail and roadway suitable:"
            v-model="form.payload.inspection.gates.rail_gap"
            name="inspection.gates.rail_gap"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Do gates show any signs of damage or impact:"
            v-model="form.payload.inspection.gates.damage"
            name="inspection.gates.damage"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
      </div>
      <div class="add-padding">
        <hr>
        <h4>Hinge Inspection:</h4>
        <fieldset>
          <combo-field
            label="Are Hinges Suitable:"
            v-model="form.payload.inspection.hinge.suitable"
            name="inspection.hinge.suitable"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Are Hinges In Suitable Condition:"
            v-model="form.payload.inspection.hinge.condition"
            name="inspection.hinge.condition"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Are Hinges Inline:"
            v-model="form.payload.inspection.hinge.inline"
            name="inspection.hinge.inline"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Have The Hinges Been Lubricated:"
            v-model="form.payload.inspection.hinge.lubricated"
            name="inspection.hinge.lubricated"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
      </div>
      <div class="add-padding">
        <hr>
        <h4>Siding Inspection:</h4>
        <fieldset>
          <combo-field
            label="Is The Rack & Track In Suitable Condition:"
            v-model="form.payload.inspection.sliding.rack_condition"
            name="inspection.sliding.rack_condition"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Are Running Wheels In Suitable Condition:"
            v-model="form.payload.inspection.sliding.wheels_condition"
            name="inspection.sliding.wheels_condition"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Is Track Free Of Debris:"
            v-model="form.payload.inspection.sliding.track"
            name="inspection.sliding.track"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
      </div>
      <div class="add-padding">
        <hr>
        <h4>Other Points:</h4>
        <fieldset>
          <combo-field
            label="Oil Levels Checked:"
            v-model="form.payload.inspection.other.oil_levels"
            name="inspection.other.oil_levels"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Are There Any Signs Of Fluid Loss:"
            v-model="form.payload.inspection.other.fluid_loss"
            name="inspection.other.fluid_loss"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Underground Box Has Suitable Drainage:"
            v-model="form.payload.inspection.other.drainage"
            name="inspection.other.drainage"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
      </div>
      <div class="add-padding">
        <hr>
        <h4>Control Panel:</h4>
        <fieldset>
          <combo-field
            label="Control Enclosure Suitable:"
            v-model="form.payload.inspection.control_panel.enclosure"
            name="inspection.control_panel.enclosure"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Enclosure Lockable:"
            v-model="form.payload.inspection.control_panel.lockable"
            name="inspection.control_panel.lockable"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Signs Of Water Ingress:"
            v-model="form.payload.inspection.control_panel.water_ingress"
            name="inspection.control_panel.water_ingress"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Cable Entry Sealed Ok:"
            v-model="form.payload.inspection.control_panel.cable_entry"
            name="inspection.control_panel.cable_entry"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Cabling Identified:"
            v-model="form.payload.inspection.control_panel.cabling"
            name="inspection.control_panel.cabling"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Is cabling of correct type:"
            v-model="form.payload.inspection.control_panel.cabling_type"
            name="inspection.control_panel.cabling_type"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Equipment Fixed In Cabinet Correctly:"
            v-model="form.payload.inspection.control_panel.cabinet"
            name="inspection.control_panel.cabinet"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
      </div>
      <div class="add-padding">
        <hr>
        <h4>Safety:</h4>
        <fieldset>
          <combo-field
            label="Are Photocells Installed:"
            v-model="form.payload.inspection.safety.photocells_installed"
            name="inspection.safety.photocells_installed"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Are Photocells Functioning Correctly:"
            v-model="form.payload.inspection.safety.functional"
            name="inspection.safety.functional"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Have Photocell Batteries Been Replaced:"
            v-model="form.payload.inspection.safety.batteries_replaced"
            name="inspection.safety.batteries_replaced"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Ground Loop Installed:"
            v-model="form.payload.inspection.safety.ground_loop"
            name="inspection.safety.ground_loop"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Are Ground Loops Functioning Correctly:"
            v-model="form.payload.inspection.safety.loop_functional"
            name="inspection.safety.loop_functional"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Are Safety Edges Installed:"
            v-model="form.payload.inspection.safety.safety_edges"
            name="inspection.safety.safety_edges"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Are Safety Edges Functioning Correctly:"
            v-model="form.payload.inspection.safety.edges_functional"
            name="inspection.safety.edges_functional"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Have Safety Edges Batteries Been Replaced:"
            v-model="form.payload.inspection.safety.edges_batteries_replaced"
            name="inspection.safety.edges_batteries_replaced"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Is Visual Indication Installed:"
            v-model="form.payload.inspection.safety.visual_indication"
            name="inspection.safety.visual_indication"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Is Visual Indication Functional:"
            v-model="form.payload.inspection.safety.indication_functional"
            name="inspection.safety.indication_functional"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
        <fieldset>
          <combo-field
            label="Is Signage Installed:"
            v-model="form.payload.inspection.safety.signage"
            name="inspection.safety.signage"
            :values="
            ['Yes', 'No', 'N/A'].map((value) => {
              return { value, label: value };
            })
          "
            mode="radio"
          ></combo-field>
        </fieldset>
      </div>
      <div class="add-padding">
        <hr>
        <h4>System Photos:</h4>
      </div>
      <evidence :original-report="report"
                :report="form"></evidence>
      <reporting :original-report="report"
                 ref="reporting"
                 :report="form"
      ></reporting>
      <quote :original-report="report"
             :report="form"></quote>
      <div class="buttons main-buttons add-padding">
        <template v-if="editable">
          <b-button
            type="is-purple is-outlined"
            @click="save"
            :loading="isLoading"
            data-test="action-update"
          >
            Save
          </b-button>
          <b-button v-if="$auth.isAdministrator"
                    type="is-purple is-outlined"
                    @click="complete"
                    :loading="isLoading"
                    data-test="action-update"
          >
            Complete
          </b-button>
          <b-button
            type="is-success"
            :disabled="$network.isOffline"
            native-type="submit"
            :loading="isLoading" v-if="$route.name === 'reports.offline.edit'">
            Sync with Admin
          </b-button>
          <template v-else>
            <b-button type="is-success"
                      v-if="!report.quote_id"
                      :loading="isLoading"
                      @click="syncSimPro">Submit to SimPRO
            </b-button>
            <a :href="simProUrl()"
               v-else
               target="_blank">
              View on SimPro
            </a>
          </template>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import payload from './payload.json'
import Quote from '@components/Quote'
import ReportBase from '@/mixins/ReportBase'
import ComboField from '@components/ComboField'
import Evidence from '@js/templates/automated-gate/Evidence'
import Reporting from '@components/Reporting'
import Collection from '@/mixins/Collection'

const components = {
  Quote,
  ComboField,
  Reporting,
  Evidence
}

export default {
  name: 'Base',
  mixins: [
    ReportBase,
    Collection
  ],
  components,
  methods: {
    originalPayload () {
      return payload
    },
    reportType () {
      return 'AutomatedGate'
    }
  }
}
</script>
