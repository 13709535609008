const options = {
  LOCATIONS: [
    'External Entrance',
    'Internal Entrance',
    'External Rear Entrance',
    'Internal Rear Entrance',
    'External Side Entrance',
    'Internal Side Entrance',
    'Ground Floor',
    'Ground Floor Lobby',
    'Ground Floor by Flat',
    'Ground Floor Lift Lobby',
    'Ground Floor Corridor',
    '1st Floor',
    '1st Floor Lobby',
    '1st Floor by Flat',
    '1st Floor Lift Lobby',
    '1st Floor Corridor',
    '2nd Floor',
    '2nd Floor Lobby',
    '2nd Floor by Flat',
    '2nd Floor Lift Lobby',
    '2nd Floor Corridor',
    '3rd Floor',
    '3rd Floor Lobby',
    '3rd Floor by Flat',
    '3rd Floor Lift Lobby',
    '3rd Floor Corridor',
    '4th Floor',
    '4th Floor Lobby',
    '4th Floor by Flat',
    '4th Floor Lift Lobby',
    '4th Floor Corridor',
    '5th Floor',
    '5th Floor Lobby',
    '5th Floor by Flat',
    '5th Floor Lift Lobby',
    '5th Floor Corridor',
    '6th Floor',
    '6th Floor Lobby',
    '6th Floor by Flat',
    '6th Floor Lift Lobby',
    '6th Floor Corridor',
    '7th Floor',
    '7th Floor Lobby',
    '7th Floor by Flat',
    '7th Floor Lift Lobby',
    '7th Floor Corridor',
    '8th Floor',
    '8th Floor Lobby',
    '8th Floor by Flat',
    '8th Floor Lift Lobby',
    '8th Floor Corridor',
    '9th Floor',
    '9th Floor Lobby',
    '9th Floor by Flat',
    '9th Floor Lift Lobby',
    '9th Floor Corridor',
    '10th Floor',
    '10th Floor Lobby',
    '10th Floor by Flat',
    '10th Floor Lift Lobby',
    '10th Floor Corridor',
    '11th Floor',
    '11th Floor Lobby',
    '11th Floor by Flat',
    '11th Floor Lift Lobby',
    '11th Floor Corridor',
    '12th Floor',
    '12th Floor Lobby',
    '12th Floor by Flat',
    '12th Floor Lift Lobby',
    '12th Floor Corridor',
    'Basement Stair Lobby',
    'Basement to Ground Floor Stairs Half Landing ',
    'Ground Floor Stairs Landing ',
    'Gound to 1st Floor Stairs Half Landing ',
    '1st Floor Stairs Landing ',
    '1st to 2nd Floor Stairs Half Landing ',
    '2nd Floor Stairs Landing ',
    '2nd to 3rd Floor Stairs Half Landing ',
    '3rd Floor Stairs Landing ',
    '3rd to 4th Floor Stairs Half Landing ',
    '4th Floor Stairs Landing ',
    '4th to 5th Floor Stairs Half Landing ',
    '5th Floor Stairs Landing ',
    '5th to 6th Floor Stairs Half Landing ',
    '6th Floor Stairs Landing ',
    '6th to 7th Floor Stairs Half Landing ',
    '7th Floor Stairs Landing ',
    '7th to 8th Floor Stairs Half Landing ',
    '8th Floor Stairs Landing ',
    '8th to 9th Floor Stairs Half Landing ',
    '9th Floor Stairs Landing ',
    '9th to 10th Floor Stairs Half Landing ',
    '10th Floor Stairs Landing ',
    '10th to 11th Floor Stairs Half Landing ',
    '11th Floor Stairs Landing ',
    '11th to 12th Floor Stairs Half Landing ',
    '12th Floor Stairs Landing ',
    'Refuse Store ',
    'Cycle Store ',
    'In Electrical Cupboard '
  ]
}

export default {
  ...options,
  map (key) {
    return options[key].map(item => {
      return {
        value: item,
        label: item
      }
    })
  }
}
