<template>
    <section>
        <div class="content">
            <header class="list-header">
                <div class="columns is-vcentered">
                    <div class="column is-6">
                        <p>Displaying <span v-if="query.data.length">{{ query.offset }}-{{ query.count}} of {{ query.total }} Archives</span></p>
                    </div>
                    <div class="column is-6">
                        <div class="buttons is-right">
                            <b-button type="is-success"
                                      @click="$boolean.set('filter', true)">
                                Filter archives
                            </b-button>
                        </div>
                    </div>
                </div>
            </header>
            <report-filter v-model="filter"
                           :templates="templates"
                           @update="updateFilter"
                           @reset="resetFilter"></report-filter>

            <p v-if="noRecords">No records found</p>
            <b-table v-else
                     v-bind="tableConfig"
                     @page-change="goToPage"
                     @sort="onSort">

              <b-table-column label="ID"
                              sortable v-slot="props">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column label="Site" v-slot="props"
                              sortable>
                {{ columnSiteName(props) }}
              </b-table-column>

              <b-table-column label="Block" v-slot="props"
                              sortable>
                {{ columnBlockName(props) }}
              </b-table-column>

              <b-table-column label="Title"
                              sortable v-slot="props">
                {{ props.row.template.label }}
              </b-table-column>

              <b-table-column label="Status"
                              sortable v-slot="props">
                {{ props.row.status }}
              </b-table-column>

              <b-table-column label="Last updated"
                              sortable v-slot="props">
                {{ props.row.updated_at | uk_datetime }}
              </b-table-column>

              <b-table-column label="Updated by"
                              sortable v-slot="props">
                {{ props.row.updated_by }}
              </b-table-column>

              <b-table-column label="Assigned by"
                              sortable v-slot="props">
                {{ props.row.assigned_by }}
              </b-table-column>

              <b-table-column v-slot="props">
                <report-actions :report="props.row"
                                @archive="archiveReport"
                                @unarchive="unarchiveReport"
                                @destroy="deleteRow"></report-actions>
              </b-table-column>
            </b-table>
        </div>
    </section>
</template>
<script>
import LaravelTable from '@js/mixins/LaravelTable'
import ResourceLaravelTable from '@js/mixins/ResourceLaravelTable'
import ReportActions from '@components/ReportActions'
import ReportFilter from '@components/ReportFilter'
import ReportTable from '@js/mixins/ReportTable'
import SiteAndBlockColumns from '@/mixins/SiteAndBlockColumns'

export default {
  name: 'Archive',
  components: {
    ReportActions,
    ReportFilter
  },
  mixins: [LaravelTable(), ResourceLaravelTable, ReportTable, SiteAndBlockColumns],
  data () {
    return {
      resource: 'archive'
    }
  },
  methods: {
    updateUrl () {
      this.$router.push({
        name: 'archive',
        query: this.filter
      })
    }
  }
}
</script>
