<template>
  <section>
    <div class="content">
      <header class="list-header">
        <div class="add-padding">
          <ul>
            <li>
              <router-link :to="route('sites')">
                <i class="fas fa-chevron-left"></i>
                <span>Back</span>
              </router-link>
            </li>
            <li><strong>Create new site</strong></li>
          </ul>
        </div>
      </header>
      <div class="add-padding">
        <form @submit.prevent="submit" class="smaller-form">
          <header class="list-header">
            <h4>Site Details</h4>
          </header>
          <div class="columns is-mobile is-multiline">
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field label="Site Name:" v-bind="showError('name')">
                <b-input type="text" v-model="form.name"></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field label="Street 1:" v-bind="showError('street1')">
                <b-input type="text" v-model="form.street1"></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field label="Street 2:" v-bind="showError('street2')">
                <b-input type="text" v-model="form.street2"></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field label="Postcode:" v-bind="showError('postcode')">
                <b-input type="text" v-model="form.postcode"></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field label="Town:" v-bind="showError('town')">
                <b-input type="text" v-model="form.town"></b-input>
              </b-field>
            </div>
          </div>
          <hr />
          <header class="list-header">
            <h4>Blocks</h4>
          </header>
          <div class="notification" v-if="!form.blocks.length">
            <p>No blocks set. Add block to this site.</p>
          </div>
          <table
            v-if="form.blocks.length"
            class="table is-bordered is-narrow is-striped is-hoverable is-fullwidth"
          >
            <thead>
              <tr>
                <th>Block Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(block, index) in form.blocks" :key="index">
                <td>{{ block.name }}</td>
                <td>
                  <div class="buttons is-right are-rounded">
                    <b-button
                      type="is-danger"
                      icon-right="trash"
                      @click="removeBlock(index)"
                    >
                    </b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="columns is-multiline">
            <div class="column is-4">
              <b-field label="Block Name">
                <b-input type="text" v-model="block.name"></b-input>
              </b-field>
            </div>
          </div>
          <b-button type="is-success" @click="addBlock"> Add block </b-button>
          <hr />
          <header class="list-header">
            <h4>Contact Details</h4>
          </header>
          <div class="columns is-mobile is-multiline">
            <div class="column is-6-mobile is-12-tablet is-4-desktop">
              <b-field label="Contact Name:" v-bind="showError('contact_name')">
                <b-input type="text" v-model="form.contact_name"></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field
                label="Contact Phone:"
                v-bind="showError('contact_phone')"
              >
                <b-input type="text" v-model="form.contact_phone"></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field
                label="Contact Email:"
                v-bind="showError('contact_email')"
              >
                <b-input type="text" v-model="form.contact_email"></b-input>
              </b-field>
            </div>
          </div>
          <hr />
          <header class="list-header">
            <h4>Property Management Details</h4>
          </header>
          <div class="columns is-mobile is-multiline">
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field
                label="Property Manager Name:"
                v-bind="showError('property_manager_name')"
              >
                <b-input
                  type="text"
                  v-model="form.property_manager_name"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field
                label="Property Management Company:"
                v-bind="showError('property_manager_company')"
              >
                <b-input
                  type="text"
                  v-model="form.property_manager_company"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field
                label="Property Manager Email:"
                v-bind="showError('property_manager_email')"
              >
                <b-input
                  type="text"
                  v-model="form.property_manager_email"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-6-mobile is-6-tablet is-4-desktop">
              <b-field
                label="Property Manager Phone:"
                v-bind="showError('property_manager_phone')"
              >
                <b-input
                  type="text"
                  v-model="form.property_manager_phone"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="buttons is-right">
            <b-button>
              <router-link :to="route('sites')"> Back to list </router-link>
            </b-button>
            <b-button native-type="submit" type="is-purple">Create</b-button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import SiteForm from '@js/site-form'

const Block = () => {
  return {
    name: ''
  }
}

export default {
  name: 'SiteCreate',
  data () {
    return {
      block: new Block(),
      form: new SiteForm()
    }
  },
  methods: {
    addBlock () {
      this.form.blocks.push(Object.assign({}, this.block))

      Object.assign(this.block, new Block())
    },
    removeBlock (index) {
      this.form.blocks.splice(index, 1)
    },
    submit () {
      return this.$server.storeResource('site', this.form).then((response) => {
        Object.assign(this.form, new SiteForm())
      })
    }
  }
}
</script>
