<template>
  <div>
    <header class="list-header">
      <div class="add-padding">
        <div class="columns is-mobile is-vcentered">
          <div class="column is-6 is-hidden-mobile">
            <p>Displaying <span v-if="query.data.length">{{ query.offset }}-{{ query.count }}/{{
                query.total
              }} results</span></p>
          </div>
          <div class="column is-12-mobile is-6-desktop">
            <div class="buttons is-right">
              <b-button type="is-success"
                        @click="$boolean.set('filter', true)">
                Filter blocks
              </b-button>
              <b-button type="is-purple is-outlined" @click="$boolean.set('createBlock', true)">
                Add block
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <b-modal :active="$boolean.get('filter')">
      <div class="content">
        <div class="filter-form">
          <header class="list-header">
            <h4>Filter blocks</h4>
          </header>
          <form @submit.prevent="updateFilter" class="smaller-form">
            <b-field label="Name">
              <b-input type="text"
                       v-model="filter.name"
              >
              </b-input>
            </b-field>
            <div class="buttons is-right">
              <b-button type="is-purple"
                        native-type="submit">Apply
              </b-button>
              <b-button type="is-success"
                        @click="resetFilter">Reset
              </b-button>
              <b-button @click="$boolean.set('filter', false)">
                Cancel
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal :active="$boolean.get('createBlock')">
      <div class="content">
        <template v-if="block.id">
          <header class="list-header">
            <h4>Edit block</h4>
          </header>
          <form @submit.prevent="updateBlock" class="smaller-form">
            <b-field label="Name">
              <b-input type="text"
                       v-model="block.name"></b-input>
            </b-field>
            <div class="buttons is-right">
              <b-button @click="closeBlockModal">Cancel</b-button>
              <b-button native-type="submit" type="is-purple">Save</b-button>
            </div>
          </form>
        </template>
        <template v-else>
          <header class="list-header">
            <h4>Create block</h4>
          </header>
          <form @submit.prevent="storeBlock" class="smaller-form">
            <b-field label="Name">
              <b-input type="text"
                       v-model="block.name"></b-input>
            </b-field>
            <div class="buttons is-right">
              <b-button @click="closeBlockModal">Cancel</b-button>
              <b-button native-type="submit" type="is-purple">Create</b-button>
            </div>
          </form>
        </template>
      </div>
    </b-modal>
    <p v-if="noRecords">No records found</p>
    <b-table v-else
             v-bind="tableConfig"
             @page-change="goToPage"
             @sort="onSort">
      <b-table-column label="ID"
                      sortable v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column label="Name"
                      sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column v-slot="props">
        <div class="buttons is-right are-rounded">
          <b-button type="open" @click="editBlock(props.row)">
            <b-icon icon="eye"></b-icon>
          </b-button>
          <b-button @click="deleteRow(props.row.id)"
                    type="is-danger"
                    icon-right="trash"/>
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
import LaravelTable, { Filter } from '@js/mixins/LaravelTable'
import ResourceLaravelTable from '@js/mixins/ResourceLaravelTable'

const Block = (attributes) => {
  return Object.assign({
    id: null,
    name: '',
    site_id: null
  }, attributes)
}

export default {
  name: 'SiteBlocks',
  mixins: [LaravelTable(), ResourceLaravelTable],
  data () {
    return {
      block: new Block({
        site_id: this.$route.params.id
      }),
      resource: 'block'
    }
  },
  methods: {
    updateUrl () {
      return this.$router.push({
        name: 'sites.blocks',
        query: this.filter,
        params: {
          id: this.$route.params.id
        }
      })
    },
    fetchData () {
      return this.$server.siteBlocks(this.$route.params.id, this.filter)
    },
    filterConfig () {
      return new Filter({
        name: ''
      })
    },
    storeBlock () {
      return this.$server.storeResource(this.resource, this.block)
        .then(response => {
          this.closeBlockModal()

          return this.load()
        })
    },
    editBlock (block) {
      Object.assign(this.block, block)

      this.$boolean.set('createBlock', true)
    },
    updateBlock () {
      return this.$server.updateResource(this.resource, this.block)
        .then(response => {
          this.closeBlockModal()

          return this.load()
        })
    },
    closeBlockModal () {
      this.$boolean.set('createBlock', false)

      Object.assign(this.block, new Block({
        site_id: this.$route.params.id
      }))
    }
  }
}
</script>
