<template>
  <section>
    <div class="content">
      <p>The page you are looking for does not exist.</p>
      <router-link :to="route('login')" data-test="view-home">Back to home page</router-link>
    </div>
  </section>
</template>
<script>
export default {}
</script>
